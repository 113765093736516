<template>
  <div :class="$style.container" ref="container">
    <a-form-model
      :model="form"
      ref="form"
      hideRequiredMark
      :class="$style.form"
      validateOnRuleChange
      :rules="rules"
    >
      <a-form-model-item
        :labelCol="{ span: 4 }"
        labelAlign="left"
        :wrapperCol="{ span: 20 }"
        prop="setPassword"
      >
        <template slot="label">分享形式：</template>
        <a-radio-group v-model="setPassword" :options="formOptions" />
        <a-input
          v-if="setPassword === '2'"
          @change="input"
          v-model="form.password"
          placeholder="设定密码，4位数或字母"
          :class="$style.password"
        />
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 4 }"
        labelAlign="left"
        :wrapperCol="{ span: 20 }"
        prop="effectiveDate"
      >
        <template slot="label">链接有效期：</template>
        <a-radio-group v-model="form.effectiveDate" :options="timeOptions" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { reportGenerate } from '@/services/things/building.js';
import { createModuleUrl } from '@/utils';
const initForm = {
  password: '',
  effectiveDate: 'SEVEN',
};
@Component
export default class ShareForm extends Vue {
  @Prop({ type: Object, required: true }) data;
  form = {};
  setPassword = '1';
  timeOptions = [
    { label: '长期有效', value: 'FOREVER' },
    { label: '7天', value: 'SEVEN' },
    { label: '1天', value: 'ONE' },
  ];
  formOptions = [
    { label: '不设密码', value: '1' },
    { label: '设密码', value: '2' },
  ];
  get rules() {
    return {
      password: [
        {
          required: this.setPassword,
          message: '请设置密码',
          trigger: 'change',
        },
        {
          len: 4,
          message: '请设置4位数密码',
          trigger: 'change',
        },
      ],
    };
  }
  created() {
    this.form = JSON.parse(JSON.stringify(initForm));
  }
  async getValue() {
    await this.$refs.form.validate();
    const { reportId, projectName } = this.data;
    const linkParams = {
      finallyEffectiveDate: this.form.effectiveDate,
      idxReportRelation: reportId,
      password: this.setPassword === '2' ? this.form.password : '',
    };
    const { idxReportRelation, password } = await reportGenerate(linkParams);
    return {
      projectName,
      reportId: idxReportRelation,
      type: 'REPORT',
      password,
      shareUrl: `${createModuleUrl(
        'm',
      )}/#/pages/iot/shareInputPassword/index?id=${idxReportRelation}&type=REPORT`,
    };
  }
  /**
   * @name 密码锁定只能用字母+数字4位
   */
  input({ target: { value } }) {
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    value = value.substring(0, 4);
    this.form.password = value;
    this.$refs.form.validateField('password');
  }
}
</script>

<style lang="less" module>
@themeColor: #05d3ff;

.container {
  color: @themeColor;
  font-size: 14px;
  padding-bottom: 90px;
  :global {
    input[type='text'] {
      background-color: transparent;
      border-color: #5bd1fa50;
      font-size: 14px;
      color: @themeColor;
      margin-left: unset;
      width: 200px;
    }
    .ant-input:hover,
    .ant-input:focus {
      border-color: @themeColor;
    }
    input::placeholder {
      color: #047396;
    }
    .ant-form-item-label label {
      color: @themeColor;
    }
    .ant-radio-wrapper {
      color: @themeColor;
    }
    .ant-radio-inner,
    .ant-radio-checked .ant-radio-inner,
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner {
      border-color: @themeColor;
    }
    .ant-radio-inner::after {
      background-color: @themeColor;
    }
    .ant-radio-wrapper:first-child {
      width: 100px;
    }
  }

  .formItem {
    display: inline-block;
    width: 26.59%;

    :global(.ant-col.ant-form-item-control-wrapper) {
      float: right;
      width: 90%;
    }
    &:first-child {
      width: 40%;
      :global(.ant-col.ant-form-item-control-wrapper) {
        width: 60%;
      }
    }
  }
  .buttonList {
    text-align: right;
    margin-top: 130px;
  }
  .inSelect {
    width: 160px;
    // margin-right: 10px;
    // &:last-child {
    //   margin-right: 0;
    // }
  }
  .password {
    width: 174px;
    margin-left: 20px;
  }
}
</style>
