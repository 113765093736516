<template>
  <div :class="$style.infoWrap" v-if="data">
    <div :class="$style.infoItem">
      <!-- <span class="TC tc-icon-bianhao" :class="$style['icon']"></span> -->
      <span :class="$style.label">{{ $t('screen.projectNo') }}: </span>
      <Ellipsis :class="$style.value" :value="data.projectSerial" />
    </div>
    <div :class="$style.infoItem">
      <!-- <span class="TC tc-icon-shujugailan" :class="$style['icon']"></span> -->
      <span :class="$style.label"
        >{{ $t('screen.SafetyProtectionPlatformEntry') }}:
      </span>
      <Ellipsis :class="$style.value" :value="data.enterDate" />
    </div>
    <div :class="$style.infoItem">
      <!-- <span class="TC tc-icon-jiwei" :class="$style['icon']"></span> -->
      <span :class="$style.label">{{ $t('screen.NumberOfMachines') }}: </span>
      <Ellipsis :class="$style.value" :value="data.seatNumber" />
    </div>
    <div :class="$style.infoItem">
      <span :class="$style.label"
        >{{ $t('screen.TotalNumberOfFloorsInTheBuilding') }}:
      </span>
      <Ellipsis :class="$style.value" :value="data.layer" />
    </div>
  </div>
  <no-data-chart v-else style="height:100%" />
</template>

<script>
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import Ellipsis from '@/components/ellipsis';

@Component({
  components: {
    Ellipsis,
  },
})
export default class FloorDetail extends Vue {
  @Prop({ type: Object, default: null }) data;
  @Inject('noVisitorMode') noVisitorMode;
  clacUnit(value) {
    if (value) {
      return `${value}${this.$t('screen.meter')}`;
    } else {
      return value;
    }
  }
}
</script>
<style lang="less" module>
.infoWrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow: hidden;
  padding: 0.3rem;
  padding-bottom: 0;
  .infoItem {
    min-width: 50%;
    max-width: 100%;
    font-size: 0.16rem;
    text-align: center;
    position: relative;
    text-align: left;
    margin-bottom: 0.2rem;
    .label {
      float: left;
      color: #a4a5a8;
      margin-right: 0.2rem;
    }
    .value {
      overflow: hidden;
      color: #e5e5e6;
    }
  }
}
</style>
