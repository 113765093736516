<template>
  <div :class="$style['wrap']">
    <div :class="$style['container']">
      <div :class="$style['header']">
        <screen-header
          @select="selectHandle"
          :rollData="projectData"
          :weatherData="weatherInfo"
          :noVisitorMode="noVisitorMode"
          :deviceInfo="deviceInfo"
          @share="shareProject"
          ref="screenHeader"
        />
      </div>
      <div :class="$style['content']">
        <div v-if="loading" :class="$style['mask']"></div>
        <div :class="$style['height_full']" v-if="!IsOutProject">
          <!-- 项目是否退场待进场 -->
          <overview
            @device-online="handleDeviceOnline"
            ref="overviewRef"
            v-if="isOverview"
            @buildIdJump="buildIdJump"
            :buildingCount="buildingCount"
          />
          <!-- 楼栋是否退场待进场 -->
          <floor
            v-if="!IsOutBuild && !isOverview"
            :buildId="querySearch.buildingId"
            :projectId="querySearch.projectId"
            @aliasChange="aliasChange"
            @rangeChange="rangeChange"
            @link-monitor="linkMonitor"
            ref="floor"
          />
          <project-out
            v-if="IsOutBuild && !isOverview"
            :text="buildOutText"
            :outTime="querySearch.quitTime"
          />
        </div>
        <project-out
          :text="projectOutText"
          :isBack="hasNotShare"
          v-else
          :outTime="proOutTime"
        />
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/reset.css';
import { Component, Vue, Provide } from 'vue-property-decorator';
import { ssoClient, SSOClientWeb } from '@triascloud/sso-client';
import { ExpiredLogin } from '@triascloud/x-blocks';
import Overview from './components/overview';
import ScreenHeader from './components/screen-header';
import Floor from './components/floor';
import ProjectOut from './components/project-out';
import GlobalWebSocket from '@/services/socket.js';
import { getWebsocketHomeId } from '@/services/iot-electric-box/socket.js';
import { createModuleUrl, Emitter } from '@/utils';
import { createModal, createFormModal } from '@triascloud/x-components';
import shareBox from './components/screen-header/share-box';
import shareForm from './components/screen-header/share-form';
import { projectModule } from '@/enum/store.js';

// /**@name 视频截图刷新 */
// const UPDATE_MONITOR_SCREENSHOT = 'UPDATE_MONITOR_SCREENSHOT';
/** @name 楼栋最后的数据 */
const OLD_DATA_BY_BUILD = 'lastDataByBuildId';
/** @name 设备上报的数据 */
const SENSOR_NOTIFY_DATA_BY_BUILD = 'triascloud-iot-electric-box_notify-sensor';
/** @name 安全防护平台提升或设备状态更新 */
const CLIMB_UP = 'triascloud-iot-electric-box_notify';
/**@name 设备预警 故障 */
const NOTIFY_WARN_ERROR = 'NOTIFY_WARN/NOTIFY_ERROR';
/** @name 设备预警-【异常】 */
const DEVICE_ERROR = 'triascloud-iot-electric-box_error';
/** @name 设备预警-【告警】  */
const DEVICE_WARN = 'triascloud-iot-electric-box_warn';
/** @name 概览项目详情 */
const PROJECT_DETAILS = 'PROJECT_DETAILS';
/** @name 概览和楼栋页面 */
const SCREEN_PROJECT = 'SCREEN_PROJECT';
import { flexDpr } from '@/assets/js/flex-dpr.js';
flexDpr();
@Component({
  components: {
    Overview,
    ScreenHeader,
    Floor,
    ProjectOut,
  },
})
export default class ProjectInfo extends Vue {
  @projectModule.State third;
  @projectModule.Mutation SET_THIRD;

  mounted() {
    Emitter.$on('Tenant_Change', () => {
      if (this.refreshTimmer) {
        clearInterval(this.refreshTimmer);
      }
    });
  }

  isOverview = true;
  loading = true;
  @Provide('noVisitorMode')
  get noVisitorMode() {
    return this.$route.fullPath.startsWith('/iot/screen/projectBuilding');
  }
  @Provide('projectVisitor')
  get projectVisitor() {
    return this.$route.fullPath.startsWith('/iot/screen/projectShare');
  }

  get hasNotShare() {
    if (this.noVisitorMode) {
      return true;
    } else {
      return false;
    }
  }
  projectData = {};
  projectStatus = 'BUILD_LOADING'; // 项目是否退场
  buildStatus = 'QUIT'; // 该楼栋是否退场
  weatherInfo = {
    temperature: 23,
    weather: '晴',
    windpower: '2',
  };
  buildingCount = 0;
  socket = null;
  deviceInfo = {
    onlineCount: 0,
    totalCount: 0,
  };
  get IsOutProject() {
    return this.projectStatus !== 'BUILD_LOADING';
  }
  /** @name  */
  get IsOutBuild() {
    return this.buildStatus === 'WAIT_ENTER' || this.buildStatus === 'QUIT';
  }
  get proOutTime() {
    let builds = JSON.parse(JSON.stringify(this.projectData.buildingList));
    builds = builds.filter(item => {
      return item.quitTime;
    });
    builds.length &&
      builds.sort((a, b) => {
        return b.quitTime - a.quitTime;
      });
    return (
      (builds.length &&
        this.$moment(builds[0].quitTime).format('YYYY-MM-DD HH:mm')) ||
      ''
    );
  }
  get projectOutText() {
    let projectType = {
      QUIT: '已退场',
      WAIT_ENTER: '待进场',
    };
    // return `该项目【${
    //   projectType[this.projectStatus]
    // }】，相关报告正在生成中敬请期待！`;
    return `该项目【${projectType[this.projectStatus]}】`;
  }
  /**
   * @description 项目状态
   * BUILD_LOADING 已进场
   * QUIT 已退场
   * WAIT_ENTER 待进场
   */
  get buildOutText() {
    let projectType = {
      QUIT: '已退场',
      WAIT_ENTER: '待进场',
    };
    // return `该楼栋【${
    //   projectType[this.buildStatus]
    // }】，相关报告正在生成中，敬请期待！`;
    return `该楼栋【${projectType[this.buildStatus]}】`;
  }
  async created() {
    this.queryParams();
    await this.init();
  }
  async init() {
    await this.initSocketLink();
  }

  querySearch = {
    projectId: '',
    buildingId: '',
    cameraId: '',
    monitor: false,
  };
  queryParams() {
    this.querySearch.projectId = this.$route.params.projectId;
    this.querySearch.buildingId = this.$route.query.b;
    this.querySearch.cameraId = this.$route.query.c;
    this.querySearch.monitor = this.$route.query.c ? true : false;
    // 如果没有参数，则跳转到运维大屏
    if (!this.querySearch.projectId && !this.projectVisitor) {
      this.$router.push({ path: `/iot/screen` });
    }
  }
  linkBuilding() {
    try {
      if (this.querySearch.buildingId) {
        this.awaitWork(() => {
          this.$refs.screenHeader.$refs.rollTab.changeCurent(
            this.querySearch.buildingId,
          );
          if (this.monitorForLink && this.querySearch.monitor) {
            this.awaitWork(() => this.$refs.floor.handleClickShowMonitor());
          }
        });
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
  awaitWork(fn, delay = 50) {
    setTimeout(() => {
      this.$nextTick(() => {
        fn();
      });
    }, delay);
  }
  monitorForLink = false;
  linkMonitor() {
    if (!this.querySearch.monitor) return;
    this.monitorForLink = true;
    if (!this.loading && this.querySearch.monitor) {
      this.awaitWork(() => this.$refs.floor.handleClickShowMonitor());
    }
  }

  /**
   * @name 访客信息
   */
  get visitorInfo() {
    return this.$store.state.generalScreen.visitorInfo;
  }
  async initSocketLink() {
    if (this.noVisitorMode) {
      const ssoOrigin = createModuleUrl('account');
      ssoClient.init(
        new SSOClientWeb({
          server: ssoOrigin,
          requestExpiredLogin: () => ExpiredLogin.create(ssoOrigin),
        }),
      );
      const token = await ssoClient.getToken();
      this.socket = new GlobalWebSocket({ token });
    } else {
      if (this.visitorInfo && this.visitorInfo.appId) {
        this.socket = new GlobalWebSocket({
          homeId:
            this.visitorInfo.type === 'PROJECT'
              ? this.visitorInfo.appId
              : await getWebsocketHomeId(this.querySearch.projectId),
        });
      } else {
        this.$router.push('/');
      }
    }
    this.socket.webSocketServerListener(this.formatEventSocket);
    this.handOverflowData();
  }
  refreshTimmer = null;
  refreshSocket(buildId) {
    this.refreshTimmer = setInterval(() => {
      this.socket.webSocketEventSend(
        `triascloud-iot-electric-box:${OLD_DATA_BY_BUILD}`,
        {
          buildId,
        },
      );
    }, 3000);
  }
  firstFlag = true;
  formatEventSocket(result) {
    const { event, data } = result;
    switch (event) {
      // case UPDATE_MONITOR_SCREENSHOT:
      //   this.$refs.floor &&
      //     this.$refs.floor.$refs.monitorRef &&
      //     this.$refs.floor.$refs.monitorRef.refresh(data);
      //   break;
      case OLD_DATA_BY_BUILD:
        this.$refs.floor && this.$refs.floor.handleSocketData(data);
        if (this.firstFlag && this.querySearch.buildingId) {
          this.firstFlag = false;
          this.refreshSocket(this.querySearch.buildingId);
        }
        break;
      case SENSOR_NOTIFY_DATA_BY_BUILD:
        if (
          this.querySearch.buildingId &&
          this.querySearch.buildingId === data
        ) {
          this.handleInitSocket(this.querySearch.buildingId);
        }
        break;
      case CLIMB_UP:
        {
          const { build, identifier, projectId } = data;
          if (identifier === 'buildRise') {
            if (
              this.querySearch.buildingId &&
              this.querySearch.buildingId === build
            ) {
              this.$refs.floor.getBuildingHeightLog();
            }
          } else if (identifier === 'deviceStatus') {
            if (
              this.querySearch.projectId &&
              this.querySearch.projectId === projectId
            ) {
              this.$refs.screenHaderRef.getProjectDeviceCountData();
            }
          }
        }
        break;
      case NOTIFY_WARN_ERROR: {
        this.handOverflowData();
        break;
      }
      case DEVICE_WARN:
      case DEVICE_ERROR:
        {
          const { projectId } = data;
          if (
            this.querySearch.projectId &&
            this.querySearch.projectId === projectId
          ) {
            this.$store.dispatch('screen/getAlarmMessageList', projectId);
          }
        }
        break;
      case PROJECT_DETAILS:
        {
          let {
            buildingList,
            city,
            name,
            projectProcess,
            isAuthority,
            isAttention,
            attentionId,
            recommend,
            weatherInfoResponse: {
              lives: [weatherInfo],
            },
          } = data;
          if (buildingList && buildingList.length) {
            this.buildingCount = buildingList.length;
          }
          this.projectData = {
            buildingList,
            city,
            name,
            isAuthority,
            isAttention,
            attentionId,
            recommend,
          };
          this.SET_THIRD({
            ...this.third,
            projectName: name,
            projectId: this.querySearch.projectId,
          });
          this.projectStatus =
            projectProcess === 'DEVICE_BIND' ? 'BUILD_LOADING' : projectProcess;
          this.weatherInfo = weatherInfo;
          this.loading = false;
          this.linkBuilding();
        }
        this.$refs.overviewRef && this.$refs.overviewRef.messageHandle(result);
        break;
      default:
        this.$refs.overviewRef && this.$refs.overviewRef.messageHandle(result);
    }
  }
  handleInitSocket(buildId, alias, dateRangeType = 'BEFORE_THIRTY_DATE') {
    this.socket.webSocketEventSend(
      `triascloud-iot-electric-box:${OLD_DATA_BY_BUILD}`,
      {
        buildId,
        alias,
        dateRangeType,
      },
    );
  }
  socketSendParams() {
    let data = {
      screenManageId: this.visitorInfo.id ? this.visitorInfo.id : '',
      password: this.visitorInfo.password ? this.visitorInfo.password : '',
      projectId: '',
    };
    if (!this.projectVisitor) {
      // 总包、租赁商、安监局大屏分享 or 分包（运维大屏跳转过来）
      data.projectId = this.querySearch.projectId;
    }
    return data;
  }
  handOverflowData() {
    const data = this.socketSendParams();
    this.socket.webSocketEventSend(
      `triascloud-iot-screen:${SCREEN_PROJECT}`,
      data,
    );
    this.mockSocket();
  }
  mockSocket() {
    this.timer = setTimeout(() => {
      if (new Date().getSeconds()) {
        this.mockSocket();
      } else {
        this.handOverflowData();
      }
    }, 1000);
  }
  clearMock() {
    this.timer && clearTimeout(this.timer);
    this.timer = null;
  }
  sameItemValue = null;

  // 当$route.params.monitor为true是，监控页面只显示在加载的楼栋
  monitorLinkOne(buildingId) {
    if (
      this.querySearch.monitor &&
      this.querySearch.buildingId !== buildingId
    ) {
      this.$router.replace({
        path: this.$route.path,
      });
      this.querySearch.monitor = false;
      this.querySearch.cameraId = '';
    }
  }
  selectHandle(item) {
    this.monitorLinkOne(item.value);
    this.SET_THIRD({
      ...this.third,
      buildingName: item.text,
      buildId: item.value,
    });

    const reportName = this.projectData.name + ' ' + item.text;
    this.isOverview = !item.value;
    this.querySearch.buildingId = item.value;
    this.buildStatus = item.climbProcess;
    if (item.climbProcess === 'QUIT') {
      this.querySearch.quitTime = this.$moment(item.quitTime).format(
        'YYYY-MM-DD HH:mm',
      );
    }
    if (this.refreshTimmer) {
      clearInterval(this.refreshTimmer);
    }
    if (item.value) {
      if (item.value !== this.sameItemValue) {
        this.firstFlag = true;
        this.sameItemValue = item.value;
        this.$nextTick(async () => {
          this.handleInitSocket(item.value);
          this.$refs.floor && (await this.$refs.floor.init(reportName));
          this.clearMock();
        });
      }
    } else {
      this.sameItemValue = null;
      this.handOverflowData();
    }
  }
  handleDeviceOnline(data) {
    this.deviceInfo = {
      onlineCount: data.sub,
      totalCount: data.total,
    };
  }
  beforeDestroy() {
    this.clearMock();
    if (this.refreshTimmer) {
      clearInterval(this.refreshTimmer);
    }
  }
  /**
   * @Param {}
   */
  aliasChange(val) {
    this.handleInitSocket(...val);
  }
  /**
   * @Param {}
   */
  rangeChange(val) {
    this.handleInitSocket(...val);
  }

  buildIdJump(buildId) {
    const index = this.projectData.buildingList.findIndex(
      e => e.pkBuildingId === buildId,
    );
    const {
      pkBuildingId: value,
      buildingName: text,
      climbProcess,
    } = this.projectData.buildingList[index];
    this.$refs.screenHeader.$refs.rollTab.select(
      { value, text, climbProcess },
      index,
    );
  }
  async shareProject() {
    const data = {
      projectId: this.$route.params.projectId,
      projectName: this.projectData.name,
    };
    const shareInfo = await createFormModal(() => <shareForm data={data} />, {
      title:
        this.$t('screen.projectOverview') + this.$t('iotScreenManage.share'),
      okText: this.$t('generalView.key.generateLink'),
      className: this.$style.shareModalForm,
      width: `628px`,
    });
    const modal = createModal(
      () => (
        <shareBox shareInfo={shareInfo} onClose={() => modal.handleClose()} />
      ),
      {
        title:
          this.$t('iotScreenManage.share') + this.$t('screen.projectOverview'),
        className: this.$style.shareModal,
        width: `420px`,
      },
    );
  }
}
</script>
<style lang="less" module>
@fontColor: #05d3ff;
.wrap {
  background: #02051b;
  color: #05d3ff;
  padding: 0.15rem;
  height: 100%;
  .container {
    display: flex;
    flex-direction: column;

    height: 100%;
    box-shadow: 0rem 0rem 0.06rem 0.02rem rgba(12, 181, 232, 0.4) inset;
    .header {
      border-bottom: 0.01rem solid rgba(13, 200, 254, 0.5);
      height: 0.8rem;
    }
    .content {
      position: relative;
      flex: 1;
      overflow: hidden;
    }
  }
  .height_full {
    height: 100%;
  }
  .mask {
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background-color: #02051b;
    z-index: 100;
  }
}
.shareModal,
.shareModalForm {
  :global {
    .ant-modal-body {
      padding: 30px 20px 20px;
      box-sizing: border-box;
      height: 214px;
      overflow-y: unset;
      margin-bottom: unset;
    }
    .ant-modal-content {
      background-color: #031d3f;
    }
    .ant-modal-header {
      color: @fontColor;
      background: #1d3453;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
    }
    .ant-modal-title {
      color: @fontColor;
      font-weight: 600;
      text-align: center;
    }
    .ant-modal-close-x {
      width: 40px;
      height: 50px;
      line-height: 50px;
      color: #5bd1fa50;
      font-size: 16px;
    }
    .ant-modal-close-x .anticon {
      vertical-align: unset;
    }
    .ant-modal-footer {
      .ant-btn {
        border-color: @fontColor!important;
        color: @fontColor!important;
      }
      .ant-btn-primary {
        background-color: @fontColor;
        color: #031d3f !important;
      }
    }
    .ant-btn:hover {
      background-color: transparent;
    }
    .ant-btn:hover.ant-btn-primary {
      background-color: @fontColor;
    }
  }
}
</style>
