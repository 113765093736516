<template>
  <div :class="$style.layler_wrap">
    <div :class="$style.layler_desc">
      <div :class="$style.label">{{ $t('generalView.table.layer') }}：</div>
      <div :class="$style.value">
        {{ data.buildingNum + $t('screen.floor') }}/{{
          data.buildingHeight + $t('screen.meter')
        }}
      </div>
    </div>
    <div :class="$style.layer_box">
      <div
        v-for="(item, index) in data.list"
        :class="[
          $style.layer,
          data.promoteLayer === item.climbNumber ? $style.promote : '',
          data.lasterLayer >= item.climbNumber ? $style.active : '',
        ]"
        :key="index"
      >
        <a-popover
          placement="bottom"
          overlayClassName="layerPopover"
          :destroyTooltipOnHide="true"
        >
          <template #content>
            <div class="floorDiv">
              <div class="popoverDot" />
              <div class="popoverTextFloor">
                {{ item.climbNumber + $t('screen.floor') }}
              </div>
            </div>
            <div class="popoverTextDate">
              <span v-if="data.promoteLayer === item.climbNumber"
                >{{ $t('screen.predictLift') }}：{{ predictionDate }}</span
              >
              <span v-if="data.lasterLayer >= item.climbNumber"
                >{{ $t('generalView.table.liftingTime') }}：{{
                  formateTimeStr(item.date)
                }}</span
              >
            </div>
            <div
              v-if="data.lasterLayer >= item.climbNumber && item.date"
              class="popoverTextDetail"
              @click="layerDetail(item)"
            >
              {{ $t('screen.ViewImprovementReport') }}
            </div>
          </template>
          <div class="popoverTextHover"></div>
        </a-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import createModal from '@/views/iot/screen/components/message/modal.js';
import PromoteDetail from '@/views/iot/screen/project-info/components/floor/promote-detail';
import dayjs from 'dayjs';

@Component
export default class LayerLog extends Vue {
  @Prop({ type: Object, required: true }) data;

  formateTimeStr(t) {
    if (t) {
      return dayjs(t).format('M/D');
    } else {
      return '--';
    }
  }

  get predictionDate() {
    return this.data.predictionDate
      ? `${this.data.promote?.date} ${dayjs(this.data.predictionDate).format(
          'HH:mm',
        )}`
      : '';
  }

  layerDetail(item) {
    createModal(() => <PromoteDetail item={item} />, {
      width: `${this.$size(500)}px`,
      height: `${this.$size(300)}px`,
      title: this.$t('generalView.buildingImReport'),
      titleStyle: {
        color: '#05D3FF',
        fontSize: `${this.$size(20)}px`,
      },
      showButton: false,
    });
  }
}
</script>

<style lang="less" module>
@lineColor: rgba(13, 200, 254, 1);
@promoteColor: rgba(255, 153, 0, 1);
.layler_wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.2rem;
  font-size: 0.14rem;
  text-align: center;
  .layler_desc {
    margin-bottom: 0.06rem;
  }
  .value {
    margin-top: 0.06rem;
    white-space: nowrap;
  }
  .layer_box {
    flex: 1;
    margin: auto;
    width: 0.6rem;
    display: flex;
    flex-direction: column;
    border: 1px solid @lineColor;
  }
  .layer {
    flex: 1;
    border-bottom: 0.01rem solid @lineColor;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent;
    box-shadow: 0 0 0.02rem 0.02rem rgba(12, 181, 232, 0.4) inset;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: @lineColor;
    }
    &.promote {
      border-color: #02051b;
      background-color: @promoteColor;
      &:hover {
        background-color: rgba(208, 128, 11, 0.99);
      }
    }
    &.active {
      background-color: @lineColor;
      border-color: #02051b;
      &:hover {
        background-color: #0aacdb;
      }
    }
  }
}
:global {
  .layerPopover {
    .ant-popover-inner {
      background-color: #031d3f;
      border: 0.01rem solid #05d3ff;
      border-radius: 0.03rem;
      box-sizing: border-box;
      width: 1.7rem;
    }
    .ant-popover-inner-content {
      color: #fff;
      padding: 0.05rem 0.05rem;
    }
    .ant-popover-arrow {
      display: none;
    }
    .floorDiv {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .popoverDot {
      display: inline-block;
      width: 0.05rem;
      height: 0.05rem;
      background: #06caf7;
      border-radius: 50%;
    }
    .popoverTextFloor {
      line-height: 0.25rem;
      margin-left: 0.05rem;
      cursor: default;
    }
    .popoverTextDate {
      line-height: 0.25rem;
      cursor: default;
    }
    .popoverTextDetail {
      line-height: 0.25rem;
      color: #05d3ff;
      cursor: pointer;
    }
  }
}
</style>

<style lang="less" scoped>
.popoverTextHover {
  opacity: 0;
  cursor: default;
  width: 100%;
  height: 100%;
}
</style>
