<template>
  <div :class="$style.height">
    <battery-level :data="errorPowerList" />
    <battery-low-level :data="seatPowerList" />
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import BatteryLevel from './charts/battery-level';
import BatteryLowLevel from './charts/battery-low-level';
@Component({
  components: {
    BatteryLevel,
    BatteryLowLevel,
  },
})
export default class BatteryStatistics extends Vue {
  @Prop({ type: Array, default: [] }) errorPowerList;
  @Prop({ type: Array, default: [] }) seatPowerList;
}
</script>

<style lang="less" module>
.height {
  height: 100%;
  width: 100%;
  display: flex;
}
</style>
