<template>
  <div :class="$style.container">
    <no-data-chart v-if="isNoData" />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize';

const lineOptions = (data, nowSize) => {
  return {
    tooltip: {
      backgroundColor: 'rgba(3,29,63,0.6)', // 通过设置rgba调节背景颜色与透明度
      color: '#fff',
      borderWidth: '1',
      borderColor: '#0DC8FE',
      padding: nowSize(4),
      textStyle: {
        color: '#fff',
        fontSize: nowSize(14),
      },
      extraCssText: 'border-radius: 0; padding: 10px;',
      formatter: '{b}: {c}个，{d}%',
    },
    series: [
      {
        type: 'pie',
        radius: ['0%', '65%'],
        center: ['46%', '50%'],
        avoidLabelOverlap: true,
        label: {
          fontSize: nowSize(12),
          show: true,
          formatter: '{b}: {c}个，{d}%',
          color: '#fff',
          textBorderColor: 'transparent',
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        labelLine: {
          show: true,
          length: 4,
          length2: 6,
        },
        data,
      },
    ],
  };
};
@Component({
  mixins: [resize],
})
export default class Attitude extends Vue {
  @Prop({ type: String, default: 'Attitude' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;

  isNoData = false;
  firstFlag = true;
  @Watch('data', { immediate: true, deep: true })
  changeData(newVal, oldVal) {
    if (!newVal || !newVal.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      this.isNoData = true;
    } else {
      if (this.firstFlag) {
        this.firstFlag = false;
        this.$nextTick(this.initChart);
      }
      if (newVal && oldVal) {
        const s1 = JSON.stringify(newVal);
        const s2 = JSON.stringify(oldVal);
        if (s1 !== s2) {
          this.$nextTick(this.initChart);
        }
      }
      this.isNoData = false;
    }
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  initChart() {
    if (!this.chart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = lineOptions(this.data, this.$size);
    this.chart && this.chart.clear();
    this.chart.setOption(options, true);
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
}
</style>
