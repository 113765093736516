import Vue from 'vue';
import Loading from './index.vue';

/**
 * @typedef Props
 * @property { String } boxEl 父容器（可选）
 * @property { String } message 提示信息
 * @property { Boolean } inline 是否内嵌（默认不是）
 * @property { Number } during 定时关闭（毫秒，默认不开启）
 */
/**
 * @example
 * createLoading({
 *   boxEl: this.$refs.screenRef.$el,
 *   message: '加载中。。。',
 *   inline: false,
 *   during: 2000,
 * }),
 * @param { Props } loadingProps
 */
export default function createLoading(loadingProps = {}) {
  const dom = document.createElement('div');
  let box = null;
  if (loadingProps.boxEl) {
    box = loadingProps.boxEl;
    box.style.position = 'relative';
  } else {
    box = document.body;
  }
  box.appendChild(dom);
  return new Vue({
    el: dom,
    data() {
      return {
        visible: true,
        loadingProps,
        clear: null,
      };
    },
    mounted() {
      if (loadingProps.during) {
        this.clear = setTimeout(() => {
          this.close();
          clearTimeout(this.clear);
        }, loadingProps.during);
      }
    },
    methods: {
      close() {
        this.visible = false;
        this.$el.parentElement?.removeChild(this.$el);
        this.$destroy();
      },
    },
    render(h) {
      return h(Loading, {
        props: {
          message: loadingProps.message,
          inline: loadingProps.inline,
        },
      });
    },
  });
}
