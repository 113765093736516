<template>
  <div :class="$style['tab-wrap']">
    <div :class="$style['header']">
      <div :class="$style['left-wrap']">
        <span
          v-for="(item, index) in title"
          :key="index"
          :class="[
            $style['left-text'],
            { [$style.active]: current == index && title.length != 1 },
            { [$style.border]: isBorder },
          ]"
          @click="select(index)"
        >
          {{ item }}
        </span>
      </div>
      <div :class="$style['right-wrap']">
        <div v-for="(item, index) in title" :key="`right_${index}`">
          <slot v-if="current == index" :name="`right_${index}`"></slot>
        </div>
      </div>
    </div>

    <div :class="$style['content']" class="tabContent">
      <div
        :class="isHeight ? $style['height'] : ''"
        v-for="(item, index) in title"
        :style="{ display: current == index ? 'block' : 'none' }"
        :key="`content_${index}`"
        class="tabInner"
      >
        <slot v-if="current == index" :name="`content_${index}`"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class InfoTab extends Vue {
  @Prop({ type: Array, default: [] }) title;
  @Prop({ type: Boolean, default: true }) isHeight;
  @Prop({ type: Boolean, default: false }) isInterval;
  @Prop({ type: Number, default: 3 }) intervalNum;
  @Prop({ type: Boolean, default: false }) isBorder;
  current = 0;
  clearTimer = null;
  select(index) {
    this.current = index;
    if (this.isInterval && this.clearTimer) {
      clearInterval(this.clearTimer);
    }
  }
  mounted() {
    if (this.isInterval) {
      this.clearTimer = setInterval(() => {
        this.timer();
      }, 5000);
    }
  }
  timer() {
    let index = this.current + 1;
    this.current = index % this.intervalNum;
  }
}
</script>

<style lang="less" module>
.tab-wrap {
  width: 100%;
  height: 100%;
  box-shadow: 0rem 0rem 0.18rem 0.02rem rgba(12, 181, 232, 0.4) inset;
  background: linear-gradient(#07d3fd, #07d3fd) left top,
    linear-gradient(#07d3fd, #07d3fd) left top,
    linear-gradient(#07d3fd, #07d3fd) right top,
    linear-gradient(#07d3fd, #07d3fd) right top,
    linear-gradient(#07d3fd, #07d3fd) left bottom,
    linear-gradient(#07d3fd, #07d3fd) left bottom,
    linear-gradient(#07d3fd, #07d3fd) right bottom,
    linear-gradient(#07d3fd, #07d3fd) right bottom;
  background-repeat: no-repeat;
  background-size: 0.02rem 0.2rem, 0.2rem 0.02rem;
  padding: 0.01rem;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0.4rem;
    line-height: 0.4rem;
    color: #05d2fe;
    background: linear-gradient(
      90deg,
      rgba(5, 211, 255, 0.2) 0%,
      transparent 60%
    );
    padding: 0 0.2rem 0 0.1rem;
    .left-text {
      font-size: 0.16rem;
      cursor: pointer;
      // padding-right: 0.2rem;
      padding: 0 0.1rem;
    }
    .border {
      border-right: 1px solid #05d2fe;
      &:last-child {
        border-right: 0;
      }
    }
    .active {
      color: #fff;
    }
  }
  .content {
    flex: 1 0 auto;
    height: 0;
    overflow: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-track-color: transparent;
    -ms-scrollbar-track-color: transparent;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  .height {
    height: 100%;
  }
}
</style>
