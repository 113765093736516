import request from '../request';
const serviceName = '/tenant-center/tenant';

/**
 * @name 成员ID获取成员基本信息
 *
 */
export function getListByIds(memberIds) {
  return request(`${serviceName}/member/listByIds`, {
    method: 'POST',
    body: memberIds,
  });
}
