<template>
  <div>
    <div v-if="codeShow" :class="$style.codeBox">
      <x-qrcode
        @click.native="$preview($refs.qrcode.imageSrc, 'image')"
        :class="$style.code"
        :src="qrcodeSrc"
        :option="qrOption"
        ref="qrcode"
      ></x-qrcode>
      <a-button :class="$style.download" type="primary" @click="handleDownload"
        ><span>
          <span class="TC tc-icon-xiazai3"></span>
          <span>{{ $t('matterwebset.screenmanage.downloadtext') }}</span>
        </span>
      </a-button>
    </div>
    <div :class="$style.projectName">
      <div v-if="shareInfo.type !== 'REPORT'">
        {{ $t('screen.shareProjectTip') }}:
      </div>
      <div v-else>{{ $t('screen.shareReport') }}:</div>
      <div :class="$style.nameDetail">【{{ shareInfo.projectName }}】</div>
    </div>
    <div :class="$style.shareBox">
      <span :class="$style.lable">
        {{ $t('matterwebset.screenmanage.linktext') }}:
      </span>
      <a-input :value="shareInfo.shareUrl" disabled :class="$style.shareUrlBox">
        <span slot="suffix" @click="handleShowQrcode">
          <i class="TC tc-icon-erweima1" :class="$style.suffix"></i>
        </span>
      </a-input>
    </div>
    <div v-if="shareInfo.password" :class="$style.shareBox">
      <span :class="$style.lable">
        {{ $t('matterwebset.screenmanage.passwordtext') }}:
      </span>
      <a-input disabled :value="shareInfo.password" :class="$style.password" />
    </div>
    <div :class="$style.buttonList">
      <a-button @click="$emit('close')"> {{ $t('msg.cancel') }} </a-button>
      <a-button type="primary" @click="copy">{{
        shareInfo.password
          ? $t('matterwebset.screenmanage.copylink')
          : $t('matterwebset.screenmanage.copyShare')
      }}</a-button>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { clipboardWrite } from '@triascloud/utils';
import XQrcode from '@/components/common/x-qrcode/index.vue';
import XDownloadAnchor from '@/components/common/x-download-anchor/index.vue';
import { createModuleUrl } from '@/utils';

@Component({ components: { XQrcode } })
export default class ShareBox extends Vue {
  @Prop({ type: Object, required: true }) shareInfo;
  codeShow = false;
  qrcodeSrc = '';
  qrOption = {
    color: {
      background: '',
      foreground: '',
    },
  };
  handleShowQrcode() {
    this.qrOption.color.background = '#091e3eFF';
    this.qrOption.color.foreground = '#57c4ebFF';
    this.codeShow = true;
    if (this.shareInfo.type === 'REPORT') {
      this.qrcodeSrc = this.shareInfo.shareUrl;
    } else {
      this.qrcodeSrc = `${createModuleUrl(
        'm',
      )}/#/pages/iot/shareInputPassword/index?id=${this.shareInfo.id}`;
    }
  }
  handleDownload() {
    XDownloadAnchor.download(this.$refs.qrcode.imageSrc, 'qrcode.png');
  }
  async copy() {
    const { shareUrl, password, type } = this.shareInfo;
    let linkName = '智能安全防护平台运维大屏链接';
    if (type === 'REPORT') {
      linkName = this.shareInfo.projectName;
    }
    await clipboardWrite(
      password
        ? `${linkName}: ${shareUrl} 密码: ${password}`
        : `${linkName}: ${shareUrl}`,
    );
    this.$message.success(this.$t('camera.replicatingSuccess'));
  }
}
</script>

<style lang="less" module>
@fontColor: #05d3ff;

.projectName {
  color: @fontColor;
  display: flex;
  .nameDetail {
    flex: 1;
  }
}
.shareBox {
  margin-top: 20px;
  position: relative;
  :global {
    .ant-input-affix-wrapper .ant-input-suffix {
      border-left: 1px solid #5bd1fa50;
      background-color: #031d3f;
    }
    .ant-input-disabled,
    .ant-input[disabled] {
      background-color: transparent;
      border-color: #5bd1fa50;
    }
    input {
      color: @fontColor!important;
    }
  }
  .lable {
    width: 40px;
    color: @fontColor;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    line-height: 32px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .shareUrlBox {
    display: inline-block;
    width: 320px;
    input {
      padding-right: 70px !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .password {
    display: inline-block;
    width: 110px;
  }
  .suffix {
    font-size: 18px;
    display: inline-block;
    text-align: center;
    width: 30px;
    color: @fontColor;
    line-height: 30px;
    cursor: pointer;
    margin-right: -10px;
  }
}
.codeBox {
  position: absolute;
  height: 100%;
  width: 246px;
  z-index: 1;
  left: calc(100% + 10px);
  top: 0;
  padding: 20px;
  background-color: #031d3f;
  text-align: center;
  box-shadow: 1px 1px 10px rgba(8, 1, 3, 0.08);
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .code {
    flex: 1;
    max-width: 206px;
    // height: 206px;
    margin-bottom: 10px;
  }
  .download {
    height: 36px;
    width: 206px;
    background-color: #5bd1fa;
    border-color: #5bd1fa;
    color: #031d3f;
  }
}
.buttonList {
  text-align: right;
  margin-top: 50px;
  button {
    margin-left: 20px;
    border-color: @fontColor;
    color: @fontColor;

    &:first-child {
      background-color: transparent !important;
      color: @fontColor;
      border-color: #5bd1fa50;
      padding-right: 36px;
      padding-left: 36px;
    }
    &:last-child {
      border-color: #5bd1fa50;
      background-color: @fontColor;
      padding-right: 30px;
      padding-left: 30px;
      color: #031d3f;
    }
  }
}
</style>
