<template>
  <div :class="$style.monitor_list">
    <div
      :class="[
        $style.monitor_li,
        item.online ? '' : $style.outline,
        cameraId === item.pkCameraId ? $style.current_monitor : '',
      ]"
      v-for="item in ordinary"
      :key="item.pkCameraId"
      @click="toMonitor(item)"
    >
      <span>{{ item.cameraName }}</span>
      <a-icon
        v-if="hasEdit"
        @click.stop="handleEditCamera(item)"
        :class="$style.cameraIcon"
        type="edit"
      />
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import createModal from '@/views/iot/screen/components/message/modal.js';
import EditCamera from './edit-camera.vue';
import { monitorModule } from '@/enum/store.js';

@Component()
export default class monitorSelect extends Vue {
  @Prop({ type: Array }) monitorList;
  @Prop({ type: Boolean, default: true }) hasEdit;

  get ordinary() {
    return this.monitorList.filter(e => e.cameraType === 'ORDINARY');
  }

  @monitorModule.Mutation updateVideo;
  @monitorModule.Mutation updateShowShot;
  @monitorModule.Mutation updateCameraId;
  @monitorModule.State cameraId;
  async toMonitor(monitorInfo) {
    this.currentItem = monitorInfo;
    this.updateShowShot(false);
    this.updateCameraId(monitorInfo.pkCameraId);
    if (!monitorInfo.online) {
      this.updateVideo({
        url: '',
        token: '',
      });
      return;
    }
    this.updateVideo({
      url: monitorInfo.url,
      token: monitorInfo.token,
    });
    this.$emit('monitor-change');
  }

  currentItem = undefined;
  handleEditCamera(item) {
    this.currentItem = item;
    createModal(
      () => (
        <EditCamera
          ref={'EditRef'}
          data={item}
          change={name => this.changeCurrentName(name)}
        />
      ),
      {
        title: '编辑摄像头',
        className: this.$style.warningModal,
        closeTxt: '取消',
        okTxt: '确定',
        ok: () => {
          this.$refs.EditRef && this.$refs.EditRef.updateCameraNameApi();
        },
      },
    );
  }
  changeCurrentName(name) {
    this.currentItem.cameraName = name;
  }
}
</script>
<style lang="less" module>
@import './monitor.less';
.monitor_list {
  width: 10%;
  overflow-y: auto;
  background: @boxBg;
  background-repeat: no-repeat;
  background-size: 0.02rem 0.2rem, 0.2rem 0.02rem;
  // border: 1px solid @lightLineColor;
  box-shadow: @boxshadow;
  .monitor_type {
    font-size: 0.18rem;
    margin-top: 0.3rem;
    margin-left: calc((100% - 1.3rem) / 2);
  }
  .monitor_li {
    width: 1.3rem;
    margin: auto;
    height: 0.46rem;
    display: flex;
    align-items: center;
    margin-top: 0.3rem;
    justify-content: center;
    font-size: 0.14rem;
    white-space: nowrap;
    background: url('~@/views/iot/screen/assets/images/monitor/monitor-bg.png')
      center center no-repeat;
    background-size: 100%;
    cursor: pointer;
    color: rgb(119, 119, 119);
    .cameraIcon {
      display: none;
      padding-left: 0.1rem;
    }

    &:hover {
      .cameraIcon {
        display: block;
      }
    }
    &.outline {
      opacity: 0.6;
    }
    &.current_monitor {
      color: #fff;
    }
    &:not(.outline):hover {
      color: #fff;
    }
    &:last-child {
      margin-bottom: 0.3rem;
    }
  }
}
</style>
