<template>
  <div :class="$style.wrap" ref="tabWrap">
    <a-tabs :class="$style.tabWrap" v-model="activeKey" @tabClick="select">
      <a-tab-pane v-for="pane in tabList" :key="pane.value">
        <span
          slot="tab"
          :class="[
            $style[colorText[pane.climbProcess]],
            { [$style.active]: activeKey === pane.value },
          ]"
        >
          {{ pane.text }}
        </span>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class RollTab extends Vue {
  @Prop({ type: Array, default: () => [] }) tabList;
  activeKey = '';
  /** @name 被外部调用
   * src\views\iot\screen\project-info\index.vue#210
   */
  changeCurent(buildId) {
    const item = this.tabList.find(item => item.value === buildId);
    this.activeKey = item.value;
    if (item) {
      this.select(item.value);
    }
  }
  select(value) {
    const item = this.tabList.find(item => item.value === value);
    if (item) {
      this.$emit('select', item);
    }
  }
  colorText = {
    DROP: 'enter',
    RISE: 'rise',
    ENTER: 'enter',
    WAIT_ENTER: 'wait_enter',
    QUIT: 'quit',
    DEVICE_BIND: 'device_bind',
    NORMAL: 'normal',
  };
}
</script>
<style lang="less" module>
.wrap {
  height: 0.8rem;
  display: flex;
  align-items: center;
}
.tabWrap {
  width: 5.54rem;
  :global {
    .ant-tabs-tab-next-icon-target,
    .ant-tabs-tab-prev-icon-target {
      font-size: 0.4rem;
      color: #05d3ff;
    }
    .ant-tabs-bar {
      margin-bottom: 0.02rem;
    }
    .ant-tabs-nav .ant-tabs-tab {
      font-size: 0.2rem;
    }
    .ant-tabs-ink-bar {
      height: 0;
    }
  }
}
.active {
  color: #ddd !important;
}
.rise {
  color: #f3e925;
}
.enter {
  color: #00eb75;
}
.wait_enter {
  color: #ff9900;
}
.quit {
  color: #b0aeae;
}
.device_bind {
  color: #ff5a20;
}
.normal {
  color: #05d3ff;
}
</style>
