<template>
  <info-tab :isInterval="false" :title="title">
    <!-- <div slot="right_0" :class="$style['rect_wrap']">
      <div :class="$style['rect_item']">
        <div :class="$style['rect']"></div>
        <div :class="$style['text']">
          预警
        </div>
      </div>
      <div :class="$style['rect_item']">
        <div :class="[$style['rect'], $style['rect-blue']]"></div>
        <div :class="$style['text']">故障</div>
      </div>
    </div> -->
    <div slot="right_0">
      <span
        v-for="(item, index) in subTitle"
        :key="index"
        @click="select(index)"
        :class="[$style['wran_btn'], { [$style['active']]: current == index }]"
        >{{ item }}</span
      >
    </div>
    <!-- <device-status slot="content_0" :data="sevenSenseData" /> -->
    <warn-error slot="content_0" :current="current" :climbList="climbList" />
    <!-- <battery-statistics
      slot="content_2"
      :errorPowerList="errorPowerList"
      :seatPowerList="seatPowerList"
    /> -->
  </info-tab>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import InfoTab from '../info-tab';
import DeviceStatus from './charts/device-status';
import WarnError from './warn-error';
import BatteryStatistics from './battery-statistics';

@Component({
  components: {
    InfoTab,
    DeviceStatus,
    WarnError,
    BatteryStatistics,
  },
})
export default class DeviceWarnBattery extends Vue {
  title = [this.$t('screen.7DayEvents')]; //['7天设备状态', '7日预警&故障', '电池电量'];
  @Prop({ type: Array, default: null }) sevenSenseData;
  @Prop({ type: Array, default: null }) errorPowerList;
  @Prop({ type: Array, default: null }) seatPowerList;
  @Prop({ type: Object, default: null }) climbList;
  current = 0;
  subTitle = [this.$t('screen.warnings'), this.$t('screen.faults')];
  select(index) {
    this.current = index;
  }
}
</script>

<style lang="less" module>
.rect_wrap {
  display: flex;
  color: #fff;
  .rect_item {
    font-size: 0.12rem;
    display: flex;
    align-items: center;
    margin-left: 0.25rem;
  }
  .rect {
    width: 0.15rem;
    height: 0.1rem;
    background: #ec7e35;
    margin-right: 0.1rem;
  }
  .rect-blue {
    background: #d41111;
  }
}
.wran_btn {
  font-size: 0.12rem;
  color: #05d2fe;
  margin-left: 0.25rem;
  display: inline-block;
  cursor: pointer;
}
.active {
  color: #fff;
}
</style>
