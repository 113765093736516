<template>
  <info-tab :title="[...title]">
    <slot slot="content_0"> </slot>
    <slot name="rightHeader" slot="right_0"> </slot>
  </info-tab>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import InfoTab from '../info-tab';
@Component({
  components: {
    InfoTab,
  },
})
export default class SimpleBox extends Vue {
  @Prop({ type: String, required: true }) title;
}
</script>
