<template>
  <div
    v-if="principals.length || customPrincipal.length"
    :class="$style.infoWrap"
    :style="{ height: `${50 * rows}%` }"
  >
    <div
      v-for="(item, index) in principals.concat(customPrincipal)"
      :style="{ height: `${100 / rows}%` }"
      :key="index"
      :class="[$style.infoItem, item.isManager ? $style.isManager : '']"
      :tabindex="index"
    >
      <span v-if="item.phone" :class="$style.phoneDetail">
        <span class="TC tc-icon-dianhua" :class="$style.phoneIcon"></span
        ><span>{{ item.phone }}</span></span
      >
      <div
        :class="$style.image"
        :style="item.avatar ? `backgroundImage: url(${item.avatar})` : ''"
      ></div>
      <div :class="$style.name">
        <span
          class="TC tc-icon-dianhua"
          v-if="item.phone"
          :class="$style.phone"
        ></span
        ><span>{{ item.name || '无' }}</span>
      </div>
      <p :class="$style.descr">{{ item.positionName }}</p>
    </div>
  </div>
  <no-data-chart v-else />
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { getListByIds } from '@/services/tenant-center/tenant.js';
import { getAvatar } from '@/services/oss/oss';
@Component
export default class PersonInfo extends Vue {
  @Prop({ type: Object, default: () => {} }) data;
  principals = [];
  customPrincipal = [];
  @Watch('data', { immediate: true, deep: true })
  async getLeadingCadre() {
    const customPrincipal = this.data.customPrincipal
      ? JSON.parse(this.data.customPrincipal).filter(
          e => e.id.trim() && e.positionName.trim(),
        )
      : [];
    const ids = Array.from(
      new Set(
        (this.data.projectPrincipalIds ?? []).concat(
          customPrincipal.filter(e => e.id).map(e => e.id),
        ),
      ),
    );
    if (!ids.length) {
      this.customPrincipal = customPrincipal;
      return;
    }
    const list = await getListByIds(ids);
    let avatars = [];
    const needsOss = list
      .filter(e => e.avatar.trim().length && !e.avatar.startsWith('http'))
      .map(e => e.avatar);
    const indexList = [];
    list.forEach((v, index) => {
      if (v.avatar) {
        indexList.push(index);
      }
    });
    if (needsOss.length) {
      const pictures = list.slice();
      const results = await getAvatar(needsOss);
      let i = 0;
      while (i < results.length) {
        pictures[indexList[i]].avatar = results[i];
        i++;
      }
      avatars = pictures
        .filter(e => e.avatar.trim().length && !e.avatar.startsWith('http'))
        .map(e => e.avatar);
    }
    /** @name 负责人默认头像 */
    this.principals = this.data.projectPrincipalIds?.map(e => {
      const current = list.find(el => el.pkId === e);
      return {
        avatar: current.avatar.startsWith('http')
          ? current.avatar
          : avatars.shift(),
        positionName: '项目负责人',
        phone: current.mobilePhone,
        name: current.memberName,
        isManager: true,
      };
    });
    this.customPrincipal = customPrincipal.map(e => {
      let avatar = null;
      if (e.id) {
        const current = list.find(el => el.pkId === e.id);
        if (current) {
          avatar =
            !current.avatar.trim().length || current.avatar.startsWith('http')
              ? current.avatar
              : avatars.shift();
        }
      }
      return {
        ...e,
        avatar,
      };
    });
  }
  get rows() {
    return Math.ceil(this.principals.concat(this.customPrincipal).length / 3);
  }
}
</script>

<style lang="less" module>
.infoWrap {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  .phone {
    position: absolute;
    transform: translateX(calc(-100% - 0.1rem));
    font-size: 0.14rem;
    margin-right: 0.1rem;
    color: #0dc8fe;
  }
  .phoneDetail {
    position: absolute;
    display: none;
    left: 50%;
    padding: 0.15rem;
    text-align: center;
    font-size: 0.14rem;
    color: #fff;
    background-color: #051231;
    border: 1px solid #06caf7;

    .phoneIcon {
      color: #8f9ebd;
      margin-right: 0.1rem;
    }
  }
  .infoItem {
    width: 33.3333333333333%;
    font-size: 0.14rem;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    &:focus {
      outline: none;
      .phoneDetail {
        display: block;
      }
    }
    &:nth-child(3n + 3) {
      .phoneDetail {
        left: unset;
        right: 50%;
      }
    }
  }
  .name {
    text-align: center;
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: #fff;
    position: relative;
  }

  .descr {
    text-align: center;
    font-size: 0.14rem;
    color: #a4a5a8;
  }
  .image {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0.1rem auto 0;
    border-radius: 100%;
    background-image: url('~@/assets/images/defaultNone.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
.isManager {
  .name,
  .descr {
    color: #0dc8fe;
  }
}
</style>
