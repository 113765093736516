<template>
  <div v-if="hasElectricBox || showMonitor" :class="$style.floor_wrap">
    <template v-if="!showMonitor">
      <div :class="$style.box_wrap">
        <!-- <div :class="$style.mes_box" v-if="errMes">{{ errMes }}</div> -->
        <div :class="$style.chart_wrap">
          <div :class="$style.chart_col">
            <!-- 机位倾斜情况汇总 -->
            <div :class="$style.chart_cell">
              <SimpleBox :title="$t('screen.subBoxTiltDistribution')">
                <div slot="rightHeader" v-if="isNewBox">
                  <div :class="$style.rect_wrap">
                    <div
                      :class="$style.rect_item"
                      :style="{ color: activePieIndex === 1 ? '#999' : '' }"
                      @click="refreshPieChart(0)"
                    >
                      {{ $t('screen.frontAndBack') }}
                    </div>
                    <div
                      :class="$style['rect_item']"
                      :style="{ color: activePieIndex === 0 ? '#999' : '' }"
                      @click="refreshPieChart(1)"
                    >
                      {{ $t('screen.leftAndRight') }}
                    </div>
                  </div>
                </div>
                <attitude :data="tiltDataResult" v-if="isShowPieChart" />
              </SimpleBox>
            </div>
            <!-- 机位载荷情况汇总 -->
            <div :class="$style.chart_cell">
              <SimpleBox :title="$t('screen.subBoxOnloadDistribution')">
                <gravityRight :data="pullData" />
              </SimpleBox>
            </div>

            <!-- 各机位电池电量情况 -->
            <div :class="$style.chart_cell">
              <SimpleBox :title="$t('screen.subBoxElectricity')">
                <battery-level
                  :data="quantityData"
                  :single="singleElectricbox"
                />
              </SimpleBox>
            </div>
          </div>
          <div :class="$style.chart_col">
            <!-- 实时监控 -->
            <div :class="$style.chart_cell_monitor">
              <monitorCarouse
                :mointorAble="monitorList && monitorList.length"
                :data="screenshotList"
                @showMonitor="showMonitor = true"
              />
            </div>
            <!-- 项目基本信息 -->
            <div :class="$style.chart_cell_info">
              <BaseInfo :data="floorDetail" :layer="layerLogData.buildingNum" />
            </div>
            <!-- 设备安全指数 -->
            <div :class="$style.chart_cell_safe">
              <SimpleBox :title="$t('screen.buildingsInfo')">
                <!-- <div
                  slot="rightHeader"
                  @click="showSafeModal"
                  :class="$style.currentSafe"
                  v-if="safeInfo && safeInfo.length"
                >
                  当前安全指数:{{ currentIndex }}%
                </div> -->
                <safebuild :data="safeIndex" />
              </SimpleBox>
            </div>
          </div>
          <div :class="$style.chart_col">
            <!-- 各机位倾斜情况 -->
            <div :class="$style.chart_cell">
              <SimpleBox :title="$t('screen.subBoxTilt')">
                <div slot="rightHeader" v-if="isNewBox">
                  <div :class="$style['rect_wrap']">
                    <div
                      :class="$style['rect_item']"
                      :style="{ color: activeLineIndex === 1 ? '#999' : '' }"
                      @click="refreshLineChart(0)"
                    >
                      {{ $t('screen.frontAndBack') }}
                    </div>
                    <div
                      :class="$style['rect_item']"
                      :style="{ color: activeLineIndex === 0 ? '#999' : '' }"
                      @click="refreshLineChart(1)"
                    >
                      {{ $t('screen.leftAndRight') }}
                    </div>
                  </div>
                </div>
                <prevHighBuilding
                  v-if="isShowLineChart"
                  :data="tiltDetailDataResult"
                  :single="singleElectricbox"
                />
              </SimpleBox>
            </div>
            <!-- 各机位载荷情况 -->
            <div :class="$style.chart_cell">
              <SimpleBox :title="$t('screen.subBoxOnload')">
                <gravityLeft
                  :data="pullDetailData"
                  :single="singleElectricbox"
                />
              </SimpleBox>
            </div>
            <!-- 总控箱电流变化情况 -->
            <div :class="$style.chart_cell">
              <SimpleBox :title="$t('screen.latest30TerminalCurrents')">
                <battery-line :data="currentData" :single="singleElectricbox" />
              </SimpleBox>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.height_wrap">
        <div
          :class="$style.selector_box"
          v-if="errMes || (electricBoxList && electricBoxList.length > 1)"
        >
          <ElectricBoxSelect
            v-if="electricBoxList && electricBoxList.length > 1"
            :data="electricBoxList"
            v-model="electricId"
            @change="aliasChange"
          />
        </div>
        <LayerLog :data="layerLogData" :class="$style.layer_log" />
      </div>
    </template>
    <Monitor
      v-else
      @close="showMonitor = !showMonitor"
      :buildId="buildId"
      :projectId="projectId"
      :monitorList="monitorList"
    />
  </div>
  <project-out
    v-else
    :text="noElectricBoxText"
    :mointorAble="monitorList && monitorList.length"
    @showMonitor="showMonitor = true"
  />
</template>

<script>
import dayjs from 'dayjs';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import SimpleBox from './floor/simpleBox';
import Monitor from './floor/monitor.vue';
import safebuild from './floor/chart/safe-build'; // 项目安全指数
import batteryLine from './floor/chart/battery-line'; // 电流传感器状态图
import gravityLeft from './floor/chart/gravity-left'; // 载荷传感器实时状态
import gravityRight from './floor/chart/gravity-right'; // 载荷传感器状态图
import prevHighBuilding from './floor/chart/prev-high-building'; // 各机位倾斜情况
import Attitude from './floor/chart/attitude-chart';
import batteryLevel from './floor/chart/battery-level-chart'; // 机位电池电量
import LayerLog from './floor/layer-log';
import monitorCarouse from './floor/monitor-carouse';
import ElectricBoxSelect from './floor/electric-box-select';
import BaseInfo from './floor/base-info';
// import { getPreview } from '@/services/monitor/camera-bind.js';
import { getCameraList } from '@/services/monitor/client.js';
import {
  getBuildingDetail,
  getBuildingHeightLog,
} from '@/services/things/building.js';
import ProjectOut from '../components/project-out';
import { getElectricByBuild } from '@/services/iot-electric-box/device-bind';
import { getScreenshot } from '@/services/monitor/client.js';
import SafeModal from '@/views/iot/screen/components/common/safe-modal';
import { dateFormatForMonthAndDay } from '@/utils';
import createModal from '@/views/iot/screen/components/message/modal.js';
import { formatPictureUrl } from './floor/monitor/monitor';
import { globalModule } from '@/enum/store.js';

/**@name 安监终端电量颜色谱 */
const colors = ['113, 113, 251', '255, 199, 85', '249, 113, 61'];
@Component({
  components: {
    SimpleBox,
    Monitor,
    safebuild,
    batteryLine,
    gravityLeft,
    gravityRight,
    prevHighBuilding,
    Attitude,
    batteryLevel,
    LayerLog,
    ElectricBoxSelect,
    monitorCarouse,
    BaseInfo,
    ProjectOut,
  },
})
export default class Floor extends Vue {
  @Prop({ type: String, required: true }) buildId;
  @Prop({ type: String }) projectId;
  @globalModule.State mock;
  /**@name 安监终端列表 */
  noElectricBoxText = `该楼栋暂未绑定安监终端，请查看其它楼栋或项目！`;
  isNewBox = true;
  activePieIndex = 0;
  activeLineIndex = 0;
  electricBoxList = null;
  hasElectricBox = false;
  errMes = '';
  colors = colors;
  showMonitor = false;
  electricId = 0;
  monitorList = [];
  /**@name 倾斜情况 */
  tiltData = null;
  /**@name 载荷情况 */
  pullData = null;
  /**@name 电池电量 */
  quantityData = null;
  /**@name 各机位倾斜 */
  tiltDetailData = null;
  /**@name 各机位载荷 */
  pullDetailData = null;
  /**@name 安监终端电流情况 */
  currentData = null;
  /**@name 安全指数 */
  safeIndex = null;
  safeInfo = null;
  /**@name 安全防护平台日志 */
  layerLogData = {
    buildingHeight: 0,
    buildingNum: 0,
    list: [],
    lasterLayer: 0,
  };
  /**@name 楼栋详情 */
  floorDetail = null;
  /**@name 截图列表 */
  screenshotList = null;
  /**@name 离线安监终端数据 */
  offLineData = [];
  isShowPieChart = true;
  isShowLineChart = true;
  refreshPieChart(index) {
    this.activePieIndex = index;
    this.tiltDataResult = index ? this.tiltDataLR : this.tiltData;
    this.isShowPieChart = false;
    this.$nextTick(() => {
      this.isShowPieChart = true;
    });
  }
  refreshLineChart(index) {
    this.activeLineIndex = index;
    this.tiltDetailDataResult = index
      ? this.tiltDetailDataLR
      : this.tiltDetailData;
    this.isShowLineChart = false;
    this.$nextTick(() => {
      this.isShowLineChart = true;
    });
  }
  /** @name 单个安监终端  */
  get singleElectricbox() {
    return this.electricBoxList && this.electricBoxList.length === 1;
  }
  /**@name 电流日期范围
   * @property {BEFORE_THIRTY_DATE,CURRENT_DATE}
   */
  // dateRangeType = 'BEFORE_THIRTY_DATE';
  get currentIndex() {
    if (!this.safeInfo || !this.safeInfo.length) return 100;
    let num = 100;
    this.safeInfo.forEach(({ gravity, gravityError, setOff }) => {
      num -= gravity + gravityError + setOff;
    });
    return Math.max(num, 0).toFixed(2);
  }
  tiltDataLR = [];
  tiltDetailDataLR = [];
  tiltDataResult = [];
  tiltDetailDataResult = [];
  getTiltData(allSensor, field) {
    const tiltData = [
      {
        value: 0,
        name: '倾斜81mm及以上',
        itemStyle: {
          color: '#7616D0',
        },
      },
      {
        value: 0,
        name: '倾斜61-80mm',
        itemStyle: {
          color: '#7272FB',
        },
      },
      {
        value: 0,
        name: '倾斜41-60mm',
        itemStyle: {
          color: '#C280FF',
        },
      },
      {
        value: 0,
        name: '倾斜21-40mm',
        itemStyle: {
          color: '#e5be5c',
        },
      },
      {
        value: 0,
        name: '倾斜0-20mm',
        itemStyle: {
          color: '#94ea70',
        },
      },
    ];
    allSensor.forEach(item => {
      let setOff = Math.abs(item[field]);
      if (setOff < 21) {
        tiltData[4].value++;
      } else if (setOff < 41) {
        tiltData[3].value++;
      } else if (setOff < 61) {
        tiltData[2].value++;
      } else if (setOff < 81) {
        tiltData[1].value++;
      } else {
        tiltData[0].value++;
      }
    });
    return tiltData.filter(e => e.value);
  }
  async handleSocketData(data) {
    let { allSensor, avgSafetyRateDTOS, safetyRateDTOS } = data;
    this.isNewBox = data.version !== 'V1';
    if (avgSafetyRateDTOS.length > 0) {
      this.safeIndex = avgSafetyRateDTOS
        .map(e => ({
          timeStamp: new Date(e.ts),
          time: dayjs(e.ts).format('MM-DD'),
          value: e.avgSafetyRate,
        }))
        .sort((a, b) => a.timeStamp - b.timeStamp);
    } else {
      if (this.mock) {
        const time = this.buildingDeviceData[0].lastOfflineTime;
        if (time === '') {
          return;
        }
        const str = this.changeStrToNumber(this.buildId);
        const valArr = this.buildingSafe(str);
        const arr = [];
        valArr.forEach((item, idx) => {
          const o = {
            time: dayjs(time)
              .subtract(6 - idx, 'day')
              .format('MM-DD'),
            value: item.value,
          };
          arr.push(o);
        });
        this.safeIndex = arr;
      }
    }
    this.safeInfo = safetyRateDTOS;
    let { amperes } = data;
    const pullData = [
      {
        value: 0,
        name: '5吨及以上',
        itemStyle: {
          color: '#E82E2A',
        },
      },
      {
        value: 0,
        name: '2～4.9吨',
        itemStyle: {
          color: '#F9713D',
        },
      },
      {
        value: 0,
        name: '0～1.9吨',
        itemStyle: {
          color: '#FFC756',
        },
      },
    ];
    const tiltDetailData = [];
    const pullDetailData = [];
    const quantityData = [];
    this.tiltDetailDataLR = [];
    allSensor.forEach(e => {
      const cId = e.alias + '-' + e.cid;
      const { setOff, gravity, battery, leftRightOffset } = e;
      const updateTime = dayjs(e.updateTime).format('YYYY-MM-DD HH:mm');
      pullData[gravity >= 5 ? 0 : gravity >= 2 ? 1 : 2].value++;
      tiltDetailData.push({
        setOff: Math.abs(setOff),
        cId,
        updateTime,
        originCid: e.cid,
      });
      this.tiltDetailDataLR.push({
        setOff: Math.abs(leftRightOffset),
        cId,
        originCid: e.cid,
        updateTime,
      });
      pullDetailData.push({
        gravity,
        cId,
        originCid: e.cid,
        updateTime,
      });
      quantityData.push({ battery, cId, axis: e.cId });
    });
    this.tiltDataLR = this.getTiltData(allSensor, 'leftRightOffset');
    this.tiltData = this.getTiltData(allSensor, 'setOff');
    this.pullData = pullData.filter(e => e.value);
    this.tiltDetailData = tiltDetailData.sort(
      (a, b) => Number(b.setOff) - Number(a.setOff),
    );
    this.tiltDetailDataLR.sort((a, b) => Number(b.setOff) - Number(a.setOff));
    this.pullDetailData = pullDetailData.sort(
      (a, b) => Number(b.gravity) - Number(a.gravity),
    );
    this.quantityData = quantityData;
    this.tiltDataResult = this.tiltData;
    this.tiltDetailDataResult = this.tiltDetailData;
    if (!amperes || !amperes.length) {
      this.currentData = null;
      if (this.mock) {
        const time = this.buildingDeviceData[0].lastOfflineTime;
        if (time === '') {
          return;
        }
        const str = this.changeStrToNumber(this.buildId);
        const valueArr = this.changeNumberToAmperes(str);
        const timeArr = this.changeTime(str, time);
        const arr = [];
        valueArr.forEach((v, idx) => {
          const o = {
            alias: '1',
            value: v.value,
            category: timeArr[9 - idx].value,
          };
          arr.push(o);
        });
        this.currentData = [arr];
        return;
      } else {
        return;
      }
    }
    if (
      !this.electricId &&
      this.electricBoxList &&
      this.electricBoxList.length > amperes.length
    ) {
      amperes = this.electricBoxList.map(e => {
        const current = amperes.find(el => el[0].alias - e.key === 0);
        if (current) return current;
        else
          return [
            {
              alias: e.key,
              ampere: 0,
              time: null,
            },
          ];
      });
    }
    this.currentData = amperes.map(e =>
      e.map(({ ampere, time, alias }) => ({
        category: time,
        value: ampere,
        alias,
        // isCurrent: this.dateRangeType === 'BEFORE_THIRTY_DATE' ? false : true,
      })),
    );
  }
  // 48- 57 0-9
  // 97 - 122  a-z
  changeStrToNumber(str) {
    let s = '';
    if (str.length < 14) {
      str += str.repeat(5);
    }
    for (let i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) >= 48 && str.charCodeAt(i) <= 57) {
        s += '' + str[i];
      } else {
        s += str.charCodeAt(i) - 96;
      }
    }
    return s;
  }
  // 楼栋安全指数
  buildingSafe(str, n = 7) {
    const res = [];
    for (let i = 0; i < n; i++) {
      const num = str.substr(-2 - i, 2);
      const o = {
        value: 99 - parseInt(num, 10) / 100,
      };
      res.push(o);
    }
    return res;
  }
  // 安监终端电流
  changeNumberToAmperes(str, n = 10) {
    const res = [];
    for (let i = 0; i < n; i++) {
      const num = str.slice(i, i + 4).split('');
      const o = {
        value: (
          70 -
          (num[0] * num[1]) / 2 -
          (num[2] * 10 + num[3]) / 100
        ).toFixed(2),
      };
      res.push(o);
    }
    return res;
  }
  changeTime(str, time, n = 10) {
    const res = [];
    for (let i = 0; i < n; i++) {
      const num = str.slice(0, i + 1).split('');
      const len = num.length;
      let sum = 1;
      for (let j = 0; j < len; j++) {
        const a = num[j] === '0' ? '1' : num[j];
        sum += parseInt(a, 10);
      }
      const o = {
        value: dayjs(time)
          .subtract(sum, 'day')
          .format('YYYY.MM.DD'),
      };
      res.push(o);
    }
    return res;
  }
  async getLayerLogData() {
    const {
      logs,
      buildingNum,
      buildingHeight,
      firstRiseFloor,
      nowDate,
      predictionDate,
    } = await getBuildingHeightLog({
      building: this.buildId,
    });
    if (buildingNum !== -1) {
      const lastLayer = logs.length
        ? +logs[logs.length - 1].num
        : firstRiseFloor
        ? firstRiseFloor
        : 0;
      const layerLogData = {
        buildingHeight: buildingHeight,
        buildingNum: buildingNum,
        list: [],
        lasterLayer: lastLayer,
      };
      // nowDate === predictionDate 不预测楼栋
      if (nowDate !== predictionDate) {
        layerLogData.promoteLayer = lastLayer + 1;
        layerLogData.predictionDate = predictionDate;
        layerLogData.promote = {
          date: dateFormatForMonthAndDay({
            currentLongDate: nowDate,
            longDate: predictionDate,
          }),
        };
      }
      for (let index = buildingNum; index; index--) {
        let data = { climbNumber: index };
        if (logs.length) {
          let item = logs.find(v => index === +v.num);
          if (item) {
            const date = dayjs(item.date);
            const month = date.format('M');
            // const day = date.format('D');
            data = {
              climbNumber: index,
              date: item.date,
              month: `${month}月`,
              buildingID: this.buildId,
              reportName: this.reportName,
            };
          }
        }
        layerLogData.list.push(data);
      }
      this.layerLogData = layerLogData;
    }
  }
  // 模拟数据 this.buildId  data
  buildingDeviceData = [];
  getElectricBoxList() {
    this.electricId = 0;
    this.buildingDeviceData = [];
    return getElectricByBuild(this.buildId).then(res => {
      if (!res.length) {
        this.hasElectricBox = false;
        throw new Error('No ElectricBox');
      }
      this.buildingDeviceData.push(res[0]);
      this.hasElectricBox = true;
      this.electricBoxList =
        res &&
        res.length &&
        res.map(({ alias, deviceStatus }) => ({
          title: `${alias}号安监终端(${
            deviceStatus === 'OFFLINE' ? '离线' : '在线'
          })`,
          key: alias,
        }));
      this.offlineElectricBox(res);
    });
  }
  offlineElectricBox(list) {
    // 是否全部离线
    this.offLineData = list.filter(el => {
      return el.deviceStatus == 'OFFLINE';
    });
    if (!this.offLineData.length) return;
    if (list.length === 1 && this.offLineData.length === 1) {
      const errMes1 = `安监终端已离线!当前统计为最后一次上传的数据。`;
      const errMes2 = this.offLineData[0].lastOfflineTime
        ? `离线时间为:${dayjs(this.offLineData[0].lastOfflineTime).format(
            'YYYY-MM-DD HH:mm:ss',
          )}`
        : '';
      this.errMes = errMes1 + errMes2;
    } else {
      const errMes1 = `${this.offLineData
        .map(e => e.alias + '号安监终端')
        .join('、')}已离线!当前统计为最后一次上传的数据。`;
      const offTimes = this.offLineData.filter(e => e.lastOfflineTime);
      const errMes2 = offTimes.length
        ? `离线时间为:${offTimes
            .map(e => dayjs(e.lastOfflineTime).format('YYYY-MM-DD HH:mm:ss'))
            .join('、')}`
        : '';
      this.errMes = errMes1 + errMes2;
    }
  }
  async getFloorDatail() {
    this.floorDetail = await getBuildingDetail(this.buildId);
  }
  async getScreenshotList() {
    const list = await getScreenshot({
      buildId: this.buildId,
      pageNum: 1,
      pageSize: 4,
    });
    this.screenshotList = await formatPictureUrl(list.records);
  }
  async initMonitorList() {
    try {
      const { records } = await getCameraList({
        buildId: this.buildId,
        pageNum: 1,
        pageSize: 100,
      });
      this.monitorList = records;
      this.linkMonitor();
    } catch (error) {
      this.$message.error(error.message);
    }
  }
  linkMonitor() {
    this.$emit('link-monitor');
  }

  handleClickShowMonitor() {
    this.showMonitor = true;
  }
  handleClickCloseMonitor() {
    this.showMonitor = false;
  }
  reportName = '';
  async init(reportName) {
    this.currentData = null;
    this.safeIndex = null;
    this.reportName = reportName;
    this.hasElectricBox = false;
    this.showMonitor = false;
    this.dateRangeType = 'BEFORE_THIRTY_DATE';
    this.errMes = '';
    await this.initMonitorList();
    await this.getElectricBoxList();
    await this.getLayerLogData();
    await this.getFloorDatail();
    await this.getScreenshotList();
  }
  @Emit('aliasChange')
  aliasChange(val) {
    return [this.buildId, val || undefined];
  }
  // rangeChange(val) {
  //   if (val === this.dateRangeType) return;
  //   this.dateRangeType = val;
  //   this.$emit('rangeChange', [
  //     this.buildId,
  //     this.electricId || undefined,
  //     val,
  //   ]);
  // }
  showSafeModal() {
    const single = this.electricBoxList.length === 1;
    const template = [
      { desc: 'descGravity', key: 'gravity' },
      { desc: 'descGravityError', key: 'gravityError' },
      { desc: 'descSetOff', key: 'setOff' },
    ];
    const data = this.safeInfo
      .flatMap(e =>
        template
          .map(el =>
            e[el.key]
              ? {
                  caseName: single
                    ? e[el.desc]
                    : e[el.desc].replace(/机位/, '机位' + e.alias + '-'),
                  safeRatio: e[el.key] + '%',
                  alias: e.alias,
                  cid: e.cid,
                }
              : null,
          )
          .filter(ex => ex),
      )
      .sort((a, b) =>
        b.safeRatio === a.safeRatio
          ? a.alias === b.alias
            ? a.cid - b.cid
            : a.alias - b.alias
          : parseFloat(b.safeRatio) - parseFloat(a.safeRatio),
      );
    const columns = [
      {
        title: '安全事例',
        key: 'caseName',
        width: 300,
      },
      {
        title: '影响系数',
        key: 'safeRatio',
        width: 100,
      },
    ];
    createModal(() => <SafeModal columns={columns} data={data} />, {
      title: `安全事例`,
      width: this.$size(508).toString(),
      showButton: false,
    });
  }
}
</script>

<style lang="less" module>
.floor_wrap {
  height: 100%;
  display: flex;
  position: relative;

  .rect_wrap {
    display: flex;
    .rect_item {
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      margin-left: 0.25rem;
      cursor: pointer;
    }
  }
  .box_wrap,
  .chart_wrap {
    flex: 1;
    overflow: hidden;
    display: flex;
    .chart_col {
      flex: 540;
      overflow: hidden;
      margin-top: 0.2rem;
      margin-left: 0.2rem;
      display: flex;
      flex-direction: column;
      &:nth-child(2) {
        flex: 604;
      }
      &:last-child {
        margin-right: 0;
      }
      .chart_cell {
        flex: 1;
        margin-bottom: 0.2rem;
      }
      .chart_cell_monitor {
        flex: 11;
        overflow: hidden;
        margin-bottom: 0.2rem;
      }
      .chart_cell_info {
        flex: 9;
        margin-bottom: 0.2rem;
        overflow: hidden;
      }
      .chart_cell_safe {
        flex: 10;
        overflow: hidden;
        margin-bottom: 0.2rem;
      }
    }
    .box_list {
      display: flex;
      justify-content: flex-end;
      color: #fff;
    }
    .box_list > div {
      margin-left: 0.1rem;
      font-size: 0.14rem;
      color: #04d2f2;
      cursor: pointer;
      &.active {
        color: #fff;
      }
    }
    .box_simple {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.14rem;
      .point {
        width: 0.16rem;
        height: 0.1rem;
        display: inline-block;
        margin-right: 0.06rem;
      }
    }
  }
  .box_wrap {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    height: 100%;
    .mes_box {
      margin-left: 0.2rem;
      height: 0.5rem;
      line-height: 0.5rem;
      text-align: center;
      color: #05d3ff;
      font-size: 0.16rem;
      background: linear-gradient(
        to right,
        rgba(5, 211, 255, 0) 0%,
        rgba(5, 211, 255, 0.2) 20%,
        rgba(5, 211, 255, 0.2) 80%,
        rgba(5, 211, 255, 0) 100%
      );
    }
  }
  .height_wrap {
    width: 1.5rem;
    padding: 0 0.2rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .selector_box {
      height: 0.5rem;
      display: flex;
      justify-items: center;
      align-items: center;
    }
    .layer_log {
      margin-top: 0.2rem;
    }
  }
}
.currentSafe {
  font-size: 0.14rem;
  cursor: pointer;
}
</style>
