<template>
  <info-tab :class="$style.infoWrap" :title="title" :isHeight="false">
    <div slot="content_0" :class="$style.height">
      <FloorDetail :data="{ ...data, layer }" />
    </div>
    <div slot="content_1" :class="$style.height">
      <person-info :data="data" />
    </div>
    <div slot="content_2" :class="$style.height">
      <OtherInfo :data="customOther" />
    </div>
  </info-tab>
</template>

<script>
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import InfoTab from '../info-tab';
import FloorDetail from './floor-detail';
import PersonInfo from './person-info';
import OtherInfo from '../overflow/other-info';
@Component({
  components: {
    InfoTab,
    FloorDetail,
    PersonInfo,
    OtherInfo,
  },
})
export default class BaseInfo extends Vue {
  @Prop({ type: Object, default: () => {} }) data;
  @Prop({ type: [String, Number], default: 0 }) layer;
  @Inject('noVisitorMode') noVisitorMode;
  get title() {
    const hasOther = this.customOther && this.customOther.length;
    return this.noVisitorMode
      ? [this.$t('screen.buildingInfo'), this.$t('screen.principals')].concat(
          hasOther ? [this.$t('screen.otherInfo')] : [],
        )
      : [this.$t('screen.buildingInfo')];
  }
  get customOther() {
    let array =
      (this.personData &&
        this.personData.customOther &&
        JSON.parse(this.personData.customOther).filter(
          e => new Set(...Object.values(e)).size,
        )) ||
      [];
    let arr = array.filter(v => v.key && v.key !== 'null');
    return arr;
  }
}
</script>

<style lang="less" module>
.infoWrap {
  :global {
    .tabContent {
      overflow-y: hidden;
    }
    .tabInner {
      height: 100%;
      overflow-y: scroll;
      scrollbar-color: transparent transparent;
      scrollbar-track-color: transparent;
      -ms-scrollbar-track-color: transparent;
    }
  }
}
.height {
  height: 100%;
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent;
}
</style>
