<template>
  <div :class="$style.container">
    <no-data-chart v-if="isNoData" />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize';
import createModal from '@/views/iot/screen/components/message/modal.js';

const lineOptions = (data, nowSize) => {
  return {
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(3,29,63,0.6)',
      borderColor: '#0DC8FE',
      borderWidth: '1',
      padding: nowSize(4),
      textStyle: {
        color: '#fff',
        fontSize: nowSize(14),
      },
      formatter: params => {
        if (params.data.tipData.status === 'yes') {
          return `充足电量电池数量，<br/>${params.value}，${params.data.tipData.percentage}`;
        } else {
          return `${params.data.tipData.buildingName}(${params.data.tipData.lowCount}个电池电量低，${params.data.tipData.seatPercentage})`;
        }
      },
      axisPointer: {
        type: 'shadow',
      },
      extraCssText: 'border-radius: 0; padding: 10px;',
      position: function(pos, params, dom, rect, size) {
        var obj = { top: 60 };
        obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
        return obj;
      },
    },
    title: {
      text: '电池电量',
      textStyle: {
        color: '#ffffff',
        fontSize: nowSize(18),
      },
      left: 'center',
      top: '40%',
      subtext: '（充足/低电量）',
      subtextStyle: {
        fontSize: nowSize(14),
        color: 'rgba(255, 255, 255, 0.5)',
      },
    },
    grid: {
      left: '2%',
      right: '4%',
      top: '0%',
      bottom: '8%',
      containLabel: true,
    },
    xAxis: {
      splitLine: {
        show: false,
      },
      type: 'value',
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
        },
      },
      axisLabel: {
        formatter: '{value}%',
      },
      axisPointer: {
        label: {
          formatter: '{value}%',
        },
      },
    },
    yAxis: {
      splitLine: {
        show: false,
      },
      type: 'category',
      nameGap: 12,
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
          fontSize: 10,
        },
      },
    },
    series: [
      {
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
        },
        labelLine: {
          show: false,
        },
        data: data.map(e => ({
          value: e.value,
          itemStyle: {
            color: e.status === 'error' ? '#EC5735' : '#418CF1',
          },
          tipData: {
            ...e,
          },
        })),
      },
    ],
  };
};
@Component({
  mixins: [resize],
})
export default class BatteryLevelChartLow extends Vue {
  @Prop({ type: String, default: 'BatteryLowLevel' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  mounted() {
    if (this.data && this.data.length) this.initChart();
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    if (!this.chart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = lineOptions(this.data, this.$size);
    this.chart.setOption(options);
    this.chart.off('click');
    this.chart.on('click', params => {
      if (params.data.tipData.status === 'error') {
        createModal(
          () => (
            <div class={this.$style.modalHeight}>
              <div class={this.$style.label}>
                {params.data.tipData.buildingName}(
                {params.data.tipData.lowPowerSeat.length}个电池电量低，
                {params.data.tipData.seatPercentage})
              </div>
              {params.data.tipData.lowPowerSeat.map((item, idx) => {
                return (
                  <div class={this.$style.descr}>
                    {idx + 1}、{item.alias}号安监终端、{item.seat}
                    号机位电池&nbsp;&nbsp;{item.power}%
                  </div>
                );
              })}
            </div>
          ),
          {
            width: `${this.$size(450)}px`,
            height: '2rem',
            title: '提示',
          },
        );
      }
    });
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  width: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
}
.modalHeight {
  text-align: center;
  padding: 0.2rem;
  margin-bottom: 0.4rem;
  max-height: 8rem;
  font-size: 0.14rem;
  overflow: auto;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent;
}
::-webkit-scrollbar {
  width: 0rem !important;
  height: 0.08rem;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}
::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}
::-webkit-scrollbar-thumb {
  background-color: #075c81;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
.label {
  margin-bottom: 0.2rem;
  font-size: 0.15rem;
  color: rgba(6, 211, 255, 1);
}
.descr {
  padding-bottom: 0.06rem;
  font-size: 0.13rem;
  color: rgba(6, 211, 255, 1);
}
</style>
