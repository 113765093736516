<template>
  <div :class="$style['out_wrap']">
    <div>
      <img :class="$style['img']" src="@/assets/images/out-projects.png" />
      <div :class="$style['tip']">
        <div>{{ text }}</div>
        <div v-if="outTime" :class="$style['tipTime']">
          退场时间：{{ outTime }}
        </div>
      </div>
      <router-link v-if="isBack" :class="$style['link']" :to="formUrl"
        >返回</router-link
      >
      <a-button
        v-if="mointorAble"
        :class="$style['link']"
        @click="$emit('showMonitor')"
        >实时监控</a-button
      >
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ProjectOut extends Vue {
  @Prop({ type: String, default: '' }) text;
  @Prop({ type: String, default: '' }) backText;
  @Prop({ type: Boolean, default: false }) isBack;
  @Prop({ type: [Boolean, Number], default: false }) mointorAble;
  @Prop({ type: String, default: '' }) outTime;
  get formUrl() {
    return this.$store.state.screen.backRouterLink;
  }
}
</script>

<style lang="less" module>
.out_wrap {
  margin: auto;
  font-size: 0.18rem;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  .img {
    width: 3.26rem;
    height: 2rem;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.3rem;
  }
  .tip {
    color: #05d2fe;
    margin-bottom: 0.7rem;
    font-size: 0.18rem;
    .tipTime {
      color: white;
      padding-top: 0.15rem;
      font-size: 0.16rem;
    }
  }
  .link {
    display: inline-block;
    color: #05d2fe;
    width: 1rem;
    height: 0.36rem;
    line-height: 0.36rem;
    font-size: 0.14rem;
    font-weight: 400;
    color: #031d3f;
    background: #05d3ff;
    border-radius: 0.05rem;
  }
}
</style>
