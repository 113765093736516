<template>
  <div :class="$style.container">
    <no-data-chart v-if="isNoData" />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize';

const colors = ['113, 113, 251', '255, 199, 85', '249, 113, 61'];

const randomRgbColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `${r}, ${g}, ${b}`;
};

const lineOptions = (data, nowSize) => {
  const isCurrent = data[0][0].isCurrent;
  let category;
  category = data
    .reduce((p, c) => p.concat(c.map(e => e.category)), [])
    .filter(e => e)
    .sort((a, b) => {
      if (isCurrent) {
        const [p1, e1] = a.split(':');
        const [p2, e2] = b.split(':');
        return p1 === p2 ? e1 - e2 : p1 - p2;
      } else {
        const [p1, e1, d1] = a.split('.');
        const [p2, e2, d2] = b.split('.');
        return p1 !== p2 ? p1 - p2 : e1 !== e2 ? e1 - e2 : d1 - d2;
      }
    });

  const values = data.map(e => {
    return category.map(el => {
      const haved = e.find(ex => ex.category === el);
      return haved ? haved.value : 0.0;
    });
  });

  /** @name 添加颜色 */
  if (values.length > colors.length) {
    const len = values.length - colors.length;
    for (let i = 0; i < len; i++) {
      colors.push(randomRgbColor());
    }
  }
  if (!isCurrent) {
    category = category.map(e => e.replace(/\d+(.)/, ''));
  }
  /** y轴的max值 */
  const dataList = [];
  data.forEach(v => v.forEach(i => dataList.push(i.value)));
  let max = Math.max(...dataList);
  // 剔除数据都是0的状况
  if (max === 0) {
    max = 10;
  } else {
    if (max >= 0 && max < 1) {
      /** @name 安监终端静默状态，电流是小数的情况， */
      max = 1;
    } else {
      max += 10 - (max % 10);
    }
  }
  const dataZoom = [
    {
      type: 'slider',
      show: values[0].length > 7,
      startValue: values[0].length - 6,
      endValue: values[0].length,
      // xAxisIndex: [0],
      height: 8, // 组件高度
      left: 40, // 左边的距离
      right: 10, // 右边的距离
      bottom: 0, // 右边的距离
      handleColor: 'transparen',
      fillerColor: '#075D82',
      borderColor: '#05314E',
      backgroundColor: '#05314E',
      handleStyle: {
        borderColor: '#cacaca',
        borderWidth: '0',
        shadowBlur: 0,
        backgroundColor: '#f8f8f8',
      },
      showDataShadow: false, // 是否显示数据阴影 默认auto
      showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
      handleIcon:
        'M-292,322.2c-3.2,0-6.4-0.6-9.3-1.9c-2.9-1.2-5.4-2.9-7.6-5.1s-3.9-4.8-5.1-7.6c-1.3-3-1.9-6.1-1.9-9.3c0-3.2,0.6-6.4,1.9-9.3c1.2-2.9,2.9-5.4,5.1-7.6s4.8-3.9,7.6-5.1c3-1.3,6.1-1.9,9.3-1.9c3.2,0,6.4,0.6,9.3,1.9c2.9,1.2,5.4,2.9,7.6,5.1s3.9,4.8,5.1,7.6c1.3,3,1.9,6.1,1.9,9.3c0,3.2-0.6,6.4-1.9,9.3c-1.2,2.9-2.9,5.4-5.1,7.6s-4.8,3.9-7.6,5.1C-285.6,321.5-288.8,322.2-292,322.2z',
      filterMode: 'filter',
    },
    {
      type: 'inside',
      show: true,
      startValue: values[0].length - 6,
      endValue: values[0].length,
      zoomOnMouseWheel: false,
    },
  ];
  const legend = {
    itemWidth: nowSize(10),
    itemHeight: nowSize(8),
    top: nowSize(6),
    data: data.map(e => ({
      name: `${e[0].alias}号安监终端`,
      icon: 'rect',
    })),
    textStyle: {
      color: '#06CAF7',
      fontSize: nowSize(12),
    },
  };
  const series = values.map((e, i) => ({
    data: e,
    type: 'line',
    symbol: 'circle',
    hoverAnimation: true,
    showSymbol: false,
    name: `${data[i][0].alias}号安监终端`,
    lineStyle: { color: `rgb(${colors[i]})` },
    areaStyle: {
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: `rgba(${colors[i]},.3)`, // 0% 处的颜色
          },
          {
            offset: 1,
            color: `rgba(${colors[i]},0)`, // 100% 处的颜色
          },
        ],
        global: false, // 缺省为 false
      },
    },
    // itemStyle: {
    //   borderColor: `rgb(${colors[i]})`,
    //   color: `rgb(${colors[i]})`,
    //   backgroundColor: `rgb(${colors[i]})`,
    // },
    itemStyle: {
      normal: {
        color: `rgb(${colors[i]})`, // 背景渐变色
        lineStyle: {
          width: 3,
          type: 'solid',
          color: `rgb(${colors[i]})`,
        },
      },
      emphasis: {
        color: `rgb(${colors[i]})`,
        borderWidth: 10,
        shadowColor: `rgb(${colors[i]},.3)`,
        shadowBlur: 3,
        borderColor: `rgb(${colors[i]},.3)`,
      },
    },
  }));
  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(3,29,63,0.6)', // 通过设置rgba调节背景颜色与透明度
      color: '#fff',
      borderWidth: '1',
      borderColor: '#0DC8FE',
      padding: nowSize(5),
      textStyle: {
        color: '#fff',
        fontSize: nowSize(14),
      },
      axisPointer: {
        type: 'line',
        z: 1,
        lineStyle: {
          color: '#fff',
          opacity: 0.2,
        },
      },
      extraCssText: 'border-radius: 0; padding: 10px;',
      formatter: function(params) {
        const tooltip = `${params[0].axisValue}${params
          .map(
            ({ marker, seriesName, data }) =>
              `<br>${marker}<span>${seriesName}</span>: <span> ${data}A</span>`,
          )
          .join('')}`;
        return tooltip;
      },
    },
    grid: {
      x: 50,
      y: 30,
      x2: 20,
      y2: 40,
    },
    legend,
    xAxis: {
      type: 'category',
      boundaryGap: true,

      data: category,
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
        },
      },
      axisLabel: {
        interval: isCurrent || category.length < 5 ? undefined : 4,
        fontSize: nowSize(12),
        rotate: 45,
      },
    },
    yAxis: {
      min: 0,
      max, // 设置y轴坐标最大值
      splitLine: {
        lineStyle: {
          show: true,
          color: ['#1c1e32'],
        },
      },
      type: 'value',
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: '#b8b8bb',
        fontSize: nowSize(12),
        formatter: '{a|{value}A}',
        width: 20,
        rich: {
          a: {
            align: 'left',
          },
        },
      },
    },
    series,
    dataZoom: isCurrent ? dataZoom : null,
  };
};
@Component({
  mixins: [resize],
})
export default class BatteryLine extends Vue {
  @Prop({ type: String, default: 'BatteryLine' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  /** @name 单个安监终端 */
  @Prop({ type: Boolean, default: false }) single;

  isNoData = false;
  firstFlag = true;
  @Watch('data', { immediate: true, deep: true })
  changeData(newVal, oldVal) {
    if (!newVal || !newVal.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      this.isNoData = true;
    } else {
      if (this.firstFlag) {
        this.firstFlag = false;
        this.$nextTick(this.initChart);
      }
      if (newVal && oldVal) {
        const s1 = JSON.stringify(newVal);
        const s2 = JSON.stringify(oldVal);
        if (s1 !== s2) {
          this.$nextTick(this.initChart);
        }
      }
      this.isNoData = false;
    }
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  initChart() {
    if (!this.chart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = lineOptions(this.data, this.$size);
    if (this.single) {
      delete options.legend;
    }
    this.chart && this.chart.clear();
    this.chart.setOption(options);
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
}
</style>
