<template>
  <div :class="$style.monitor">
    <div :class="$style.header">
      <i
        class="iconfont iconfanhui"
        :class="$style.return_btn"
        @click="hideMonitor"
      ></i>
      <span :class="$style.title">{{ $t('screen.monitor') }}</span>
    </div>
    <div :class="$style.content">
      <div :class="$style.monitor_content">
        <monitor-select
          ref="monitorSelect"
          :monitorList="monitorList"
          @monitor-change="monitorChange"
        />
        <monitor-play ref="monitorPlay" />
        <monitor-picture
          ref="monitorPicture"
          @change-carousel="gotoCarousel"
          @warn-form="openWarningModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import MonitorPlay from './monitor/monitor-play.vue';
import MonitorSelect from './monitor/monitor-select.vue';
import MonitorPicture from './monitor/monitor-picture.vue';
import { monitorModule } from '@/enum/store.js';

@Component({
  components: {
    MonitorPlay,
    MonitorSelect,
    MonitorPicture,
  },
})
export default class Monitor extends Vue {
  @Prop({ type: Array, default: () => [] }) monitorList;

  // @Inject('noVisitorMode') noVisitorMode;

  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  }

  gotoCarousel(val) {
    this.$refs.monitorPlay && this.$refs.monitorPlay.gotoCarousel(val);
  }
  openWarningModal(value) {
    this.$refs.monitorPlay && this.$refs.monitorPlay.openWarningModal(value);
  }

  monitorChange() {
    setTimeout(() => {
      this.$refs.monitorPicture.resetMonitor();
    }, 0);
  }

  @Emit('close')
  hideMonitor() {}

  init() {
    this.initMonitorList();
    this.$refs.monitorPicture.getScreenshotList();
  }
  @monitorModule.Mutation updateCameraId;
  /**
   * @name 获取摄像头列表
   */
  initMonitorList() {
    const toMonitor = this.$refs.monitorSelect.toMonitor;
    // 匹配路由中的摄像头
    if (this.$route.query.c) {
      const result = this.monitorList.find(
        v => v.pkCameraId === this.$route.query.c,
      );
      if (result) {
        this.updateCameraId(result.pkCameraId);
        toMonitor(result);
      } else {
        toMonitor(this.monitorList[0]);
      }
    } else {
      const onlineExist = this.monitorList.find(e => e.online);
      if (onlineExist) {
        this.updateCameraId(onlineExist.pkCameraId);
        toMonitor(onlineExist);
      } else {
        toMonitor(this.monitorList[0]);
      }
    }
  }

  // refresh(value = { cameraId: -1 }) {
  //   this.$refs.monitorPicture.refresh(value);
  // }
}
</script>

<style lang="less" module>
@import './monitor/monitor.less';
.monitor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: @fontColor;
  background: @bgColor;
  border: 1px solid @lightLineColor;
  border-top: none;
  .header {
    height: 0.66rem;
    text-align: center;
    position: relative;
    line-height: 0.66rem;
    border-bottom: 0.01rem solid @lightLineColor;
    .title {
      font-size: 0.3rem;
    }
    .return_btn {
      cursor: pointer;
      font-size: 0.22rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0.2rem;
    }
  }
  .content {
    flex: 1;
    overflow: hidden;
    .monitor_content {
      display: flex;
      padding: 0.12rem;
      height: 100%;
      justify-content: space-between;

      .monitor_area {
        flex: 1;
        margin: 0 0.2rem;
        border: 1px solid @lineColor;
        overflow: hidden;
        box-shadow: @boxshadow;
        .sreenshot();
        .iframe {
          .sreenshot();
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: relative;
          z-index: 9999;
        }
        .screenshot_showarea {
          height: 100%;
          width: 100%;
          position: relative;
        }
        .carousel {
          height: 100%;
          :global(.slick-slider) {
            height: 100%;
          }
          :global(.slick-list) {
            height: 100%;
          }
          :global(.slick-track) {
            height: 100%;
            div {
              height: 100%;
            }
            img {
              height: 100%;
            }
          }
          .carousel_arrow_l {
            color: #fff;
            z-index: 101;
            font-size: 0.64rem;
            left: 0.2rem;
            color: @fontColor;
          }
          .carousel_arrow_r {
            color: #fff;
            z-index: 101;
            font-size: 0.64rem;
            right: 0.6rem;
            color: @fontColor;
          }
        }
        .screenshot_buttons {
          position: absolute;
          top: 0.1rem;
          right: 0.5rem;
          z-index: 100;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .button {
            cursor: pointer;
            border: 1px solid @lineColor;
            background-color: @bgColor;
            padding: 0.04rem 0.1rem;
            margin-right: 0.1rem;
            font-size: 0.14rem;
            i {
              margin-right: 0.1rem;
            }
          }
          .iconfont {
            font-size: 0.14rem;
          }
        }
        .outlineBox {
          display: flex;
          align-items: center;
          height: 100%;
          justify-content: center;
          .outlineIcon {
            font-size: 2rem;
            color: #073b6a;
          }
          p {
            margin-top: 0.42rem;
            font-size: 0.14rem;
            color: #096c9a;
          }
        }
      }
    }
  }
}
.warningModal {
  :global(.ant-modal-body) {
    padding-top: 0.15rem;
    margin-bottom: 0.15rem;
  }
  :global(.ant-modal-content) {
    background-color: @bgColor;
    border-radius: unset;
  }
  :global(.ant-modal-header) {
    color: @fontColor;
    background: linear-gradient(
      90deg,
      rgba(5, 211, 255, 0.2) 0%,
      transparent 98%
    );
    border: 1px solid @lightLineColor;
    box-shadow: @boxshadow;
    padding: 0.1rem 0.14rem;
    font-size: 0.14rem;
    border-radius: unset;
  }
  :global(.ant-modal-title) {
    color: @fontColor;
    font-weight: 200;
    font-size: 0.14rem;
    text-align: left;
  }
  :global(.ant-modal-close-x) {
    width: 0.4rem;
    height: 0.34rem;
    line-height: 0.34rem;
    color: @fontColor;
    font-size: 0.14rem;
    padding: 0.05rem 0.14rem;
  }
  :global(.ant-modal-close-x .anticon) {
    vertical-align: unset;
  }
}
</style>
