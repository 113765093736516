<template>
  <div class="sk-cube-box" :class="[inline ? 'inline' : 'default']">
    <div class="sk-cube-content">
      <div class="sk-cube-grid">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
      </div>
      <div class="sk-cube-text" v-if="message">
        {{ message }}
      </div>
    </div>
    <div class="sk-cube-mark"></div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component()
export default class Loading extends Vue {
  @Prop({ type: String, default: '' }) message;
  /** @name 是否嵌套 */
  @Prop({ type: Boolean, default: false }) inline;
}
</script>
<style lang="less" scoped>
.sk-cube-box {
  z-index: 100;
  &.default {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &.inline {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.sk-cube-mark {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(2, 4, 22, 0.5);
}
.sk-cube-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .sk-cube-grid {
    width: 0.4rem;
    height: 0.4rem;
    margin: 0 auto;
  }
  .sk-cube-text {
    margin-top: 0.2rem;
    font-size: 0.16rem;
    color: #fff;
  }
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #05d3ff;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s;
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}
</style>
