<template>
  <div :class="$style.infoWrap">
    <div v-for="(item, index) in data" :key="index" :class="$style.infoItem">
      <span :class="$style.label">{{ item.key }}: </span>
      <Ellipsis :class="$style.value" :value="item.value" />
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import Ellipsis from '@/components/ellipsis';

@Component({
  components: {
    Ellipsis,
  },
})
export default class OtherInfo extends Vue {
  @Prop({ type: Array, default: () => [] }) data;
}
</script>

<style lang="less" module>
.infoWrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow: hidden;
  padding: 0.3rem;
  padding-bottom: 0;
  .infoItem {
    min-width: 50%;
    max-width: 100%;
    font-size: 0.16rem;
    text-align: center;
    position: relative;
    text-align: left;
    margin-bottom: 0.2rem;
    .label {
      float: left;
      color: #a4a5a8;
      margin-right: 0.2rem;
    }
    .value {
      overflow: hidden;
      color: #e5e5e6;
    }
  }
}
</style>
