<template>
  <div :class="$style['progress']">
    <div
      :class="$style['progress-item']"
      v-for="(item, index) in data.processVO"
      :key="index"
    >
      <div :class="$style['line']">
        <div :class="$style['dot']"></div>
        <div :class="$style['text']">
          <div :class="$style['value']">{{ item.name }}</div>
          <div>{{ item.value }}</div>
        </div>
        <div v-if="index == arr.length - 1" :class="$style['last-dot']">
          <span
            :class="$style['last-dot-icon']"
            class="TC tc-icon-shuangjiantou"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ProgressEvent extends Vue {
  arr = [1, 2, 3, 4, 5, 6, 7, 8];
  @Prop({ type: Object, default: () => {} }) data;
}
</script>

<style lang="less" module>
.progress {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 0.1rem;
  .progress-item {
    padding-top: 0.3rem;
    width: 10%;
    .line {
      // width: 1.72rem;
      width: 100%;
      height: 0.04rem;
      background: rgba(5, 211, 255, 0.5);
      position: relative;
      .dot {
        position: absolute;
        left: -0.15rem;
        top: -0.13rem;
        width: 0.18rem;
        height: 0.18rem;
        border-radius: 50%;
        background: #05d3ff;
        background-clip: padding-box;
        box-sizing: content-box;
        border: 6px solid rgba(5, 211, 255, 0.2);
      }
      .text {
        position: absolute;
        top: 0.25rem;
        width: 1.2rem;
        text-align: center;
        left: -0.6rem;
        font-size: 0.12rem;
        color: rgba(255, 255, 255, 0.8);
        .value {
          margin-bottom: 0.05rem;
        }
      }
    }
    &:last-child .dot {
      background: #ec7e35;
      background-clip: padding-box;
      box-sizing: content-box;
      border: 6px solid rgba(236, 126, 53, 0.4);
    }
    .last-dot {
      position: absolute;
      height: 0.26rem;
      width: 0.26rem;
      right: -0.13rem;
      top: -0.13rem;
      background: #05d3ff;
      border-radius: 50%;
      color: #000;
      line-height: 0.26rem;
      text-align: center;
      .last-dot-icon {
        display: inline-block;
        height: 0.26rem;
        width: 0.26rem;
        line-height: 0.26rem;
        font-size: 0.14rem;
        text-align: center;
      }
    }
  }
}
</style>
