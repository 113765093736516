<template>
  <info-tab :title="title">
    <div slot="content_0" :class="$style.container">
      <no-data-chart v-if="isNoData" />
      <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
    </div>
  </info-tab>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize';
import { nowSize } from '@/utils/common.js';
import InfoTab from '../../info-tab';
import {
  Color_Waiting,
  Color_Building,
  Color_Outing,
  Color_Electricbox,
} from '@/components/main-screen/final.js';

const lineOptions = (data, $t) => {
  const keyFormat = {
    WAIT_ENTER: $t('screen.legend.waitEnter'),
    ENTER: $t('screen.legend.enter'),
    RISE: $t('screen.legend.rise'),
    DROP: $t('screen.legend.drop'),
    QUIT: $t('screen.legend.quit'),
    DEVICE_BIND: $t('screen.btn.terminalsInstalled'),
  };
  const category = [];
  const values = [];
  // 只剩下项目的状态
  const list = data.filter(
    item => item.status !== 'RISE' && item.status !== 'DROP',
  );
  const total = list.reduce((p, c) => p + c.number, 0);
  list.forEach(item => {
    const colorList = {
      WAIT_ENTER: Color_Waiting,
      ENTER: Color_Building,
      QUIT: Color_Outing,
      DEVICE_BIND: Color_Electricbox,
    };
    category.push(keyFormat[item.status]);
    values.push({
      value: item.number,
      name: keyFormat[item.status],
      precentage: `${keyFormat[item.status]}：${item.number}，${(
        (item.number / total) *
        100
      ).toFixed(0)}%`,
      itemStyle: {
        color: colorList[item.status],
      },
    });
  });
  return {
    backgroundColor: '',
    title: {
      left: 'center',
    },
    grid: {
      x: 50,
      y: 30,
      x2: 20,
      y2: 30,
    },
    legend: {
      orient: 'vertical',
      right: '5%',
      top: 'center',
      bottom: 20,
      height: '100%',
      data: category,
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 10,
      padding: 10,
      icon: 'rectangle',
      textStyle: {
        color: '#b8b8bb',
        padding: [0, 6, 0, 8],
        fontSize: nowSize(12),
      },
    },
    series: [
      {
        type: 'pie',
        radius: '60%',
        center: ['35%', '50%'],
        label: {
          fontSize: nowSize(12),
          formatter: params => {
            return params.data.precentage;
          },
          show: true,
          color: '#fff',
          textBorderColor: 'transparent',
        },
        labelLine: {
          smooth: 0.2,
          length: 10,
          length2: 20,
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        data: values,

        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
};
@Component({
  mixins: [resize],
  components: {
    InfoTab,
  },
})
export default class PieChart extends Vue {
  @Prop({ type: String, default: 'PieChart' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  title = [this.$t('screen.terminalStatus')];
  mounted() {
    if (this.data && this.data.length) this.initChart();
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  chart = null;
  initChart() {
    if (!this.chart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = lineOptions(this.data, this.$t);
    this.chart.setOption(options);
  }
  resizeChart() {
    this.chart && this.chart.resize();
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
}
</style>
