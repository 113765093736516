<template>
  <div :class="$style.container">
    <div v-if="mointorAble" :class="$style.btn" @click="showMonitor">
      <span>{{ $t('screen.monitor') }} 》</span>
    </div>
    <a-carousel v-if="data && data.length" arrows :class="$style.carousel">
      <template #prevArrow>
        <a-icon type="left" :class="$style.carousel_arrow_l" />
      </template>
      <template #nextArrow>
        <a-icon type="right" :class="$style.carousel_arrow_r" />
      </template>
      <img v-for="item in data" :key="item.id" :src="item.screenshotUrl" />
    </a-carousel>
    <no-data-chart v-else />
  </div>
</template>

<script>
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class MonitorCarouse extends Vue {
  @Prop({ type: Array, default: null }) data;
  @Prop({ type: [Boolean, Number], default: true }) mointorAble;
  @Emit('showMonitor')
  showMonitor() {}
}
</script>

<style lang="less" module>
@fontColor: rgba(5, 211, 255, 1);
.container {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0rem 0rem 0.18rem 0.02rem rgba(12, 181, 232, 0.4) inset;
  background: linear-gradient(#07d3fd, #07d3fd) left top,
    linear-gradient(#07d3fd, #07d3fd) left top,
    linear-gradient(#07d3fd, #07d3fd) right top,
    linear-gradient(#07d3fd, #07d3fd) right top,
    linear-gradient(#07d3fd, #07d3fd) left bottom,
    linear-gradient(#07d3fd, #07d3fd) left bottom,
    linear-gradient(#07d3fd, #07d3fd) right bottom,
    linear-gradient(#07d3fd, #07d3fd) right bottom;
  background-repeat: no-repeat;
  background-size: 0.02rem 0.2rem, 0.2rem 0.02rem;
  padding: 0.02rem;
}
.btn {
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  right: 0.26rem;
  top: 0.18rem;
  font-size: 0.2rem;
  text-align: center;
  font-weight: normal;
  color: #02051b;
  background: rgba(2, 5, 27, 0.3);
  border: 1px solid rgba(5, 211, 255, 0.5);
  width: 1.32rem;
  height: 0.5rem;
  cursor: pointer;
  span {
    display: block;
    height: 0.42rem;
    margin: 0.03rem;
    line-height: 0.42rem;
    background: rgba(5, 211, 255, 1);
  }
  &.mointorDisable {
    filter: brightness(0.5);
    cursor: not-allowed;
  }
}
.carousel {
  width: 100%;
  height: 100%;
  :global(.slick-dots-bottom) {
    bottom: 0.2rem;
  }
  :global(.slick-dots li) {
    width: 0.1rem;
    height: 0.1rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 0.04rem;
    button {
      width: 100%;
      height: 100%;
      background-color: @fontColor;
    }
  }
  :global(.slick-slider) {
    height: 100%;
  }
  :global(.slick-list) {
    height: 100%;
  }
  :global(.slick-track) {
    height: 100%;
    div {
      height: 100%;
    }
    img {
      height: 100%;
    }
  }
  .carousel_arrow_l {
    z-index: 101;
    font-size: 0.35rem;
    left: 0.16rem;
    color: @fontColor;
    &:focus,
    &:hover {
      color: @fontColor;
    }
  }
  .carousel_arrow_r {
    z-index: 101;
    font-size: 0.35rem;
    right: 0.32rem;
    color: @fontColor;
    &:focus,
    &:hover {
      color: @fontColor;
    }
  }
}
</style>
