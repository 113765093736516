<template>
  <div :class="$style.warningModal">
    <x-upload
      :class="$style.Upload"
      v-model="value.upload"
      uploadPath="/oss/backend/getAuth"
      @input="$emit('input', { ...value, upload: $event })"
      :maxFileSize="20"
      :max="10"
    ></x-upload>
  </div>
</template>

<script>
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { monitorScreenshot } from '@/services/monitor/client';

@Component({})
export default class UploadImage extends Vue {
  @Prop({ type: String }) cameraId;

  value = {
    upload: [],
  };

  sendLoading = false;
  async updateCameraNameApi() {
    this.sendLoading = true;
    try {
      if (!this.value.upload.length) {
        throw new Error('请先上传图片！');
      }
      await monitorScreenshot({
        cameraId: this.cameraId,
        screenshotUrl: this.value.upload[0].ossPath,
        screenshotTime: Date.now(),
        alarmMessageType: 'MANUAL', // 人工截图类型
      });
      this.success();
      this.close();
    } catch (error) {
      this.$message.error(error.message);
    } finally {
      this.sendLoading = false;
    }
  }
  @Emit('close')
  close() {}

  @Emit('success')
  success() {}
}
</script>

<style lang="less" module>
@commonColor: rgba(13, 200, 254, 1);
.warningModal {
  padding: 0 0.2rem;
  color: #fff;
  :global(.x-upload--button:hover),
  :global(.x-upload--button:focus) {
    color: @commonColor;
    background-color: transparent;
    border: 1px solid @commonColor;
  }
  :global(.x-upload--fileList-oneFile-icon-edit) {
    color: @commonColor;
  }
  :global(.x-upload .x-upload-button),
  :global(.x-upload--fileList-oneFile) {
    max-width: 100%;
  }
  :global(.ant-radio) {
    font-size: 0.14rem;
  }
  :global(.ant-radio-inner) {
    width: 0.14rem;
    height: 0.14rem;
    top: -0.01rem;
    border-radius: 1rem;
  }
  :global(.ant-radio-inner::after) {
    background-color: @commonColor;
    top: 0.03rem;
    left: 0.03rem;
    width: 0.06rem;
    height: 0.06rem;
    border-radius: 0.08rem;
  }
  :global(span.ant-radio + *) {
    font-size: 0.14rem;
  }
  :global(label) {
    color: #fff;
  }
  .textarea {
    border-color: @commonColor;
    margin-top: 0.1rem;
    color: #fff;
    font-size: 0.14rem;
    :global(.ant-input) {
      font-size: 0.14rem;
    }
  }
  .Upload {
    width: 100%;
    :global(.x-upload--uploader) {
      display: block;
      width: 100%;
    }
    :global(.ant-upload) {
      width: 100%;
    }
  }
  p {
    margin: 0.2rem 0;
    font-size: 0.14rem;
  }
  img {
    width: 3rem;
    height: 1.2rem;
    border-radius: 0.05rem;
  }
}
</style>
