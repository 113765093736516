<template>
  <div :class="$style.container">
    <no-data-chart v-if="isNoData" />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize';
import { globalVueI18n } from '@triascloud/i18n';

const scatterOptions = (data, nowSize, single) => {
  data = data.map((item, index) => {
    let obj = {
      color: '',
    };
    let symbolSize = 20;
    const { battery, cId } = item;
    if (battery < 10) {
      symbolSize = 40;
      obj = {
        color: 'rgba(236, 88, 54, 1)',
        shadowBlur: 10,
        shadowColor: 'rgba(236, 88, 54, 0.5)',
      };
    } else if (battery >= 10 && battery < 20) {
      symbolSize = 36;
      obj = {
        color: 'rgba(245, 111, 22, 1)',
        shadowBlur: 10,
        shadowColor: 'rgba(245, 111, 22, 0.5)',
      };
    } else if (battery >= 20 && battery < 30) {
      symbolSize = 32;
      obj = {
        color: 'rgba(245, 138, 22, 1)',
        shadowBlur: 10,
        shadowColor: 'rgba(245, 138, 22, 0.5)',
      };
    } else if (battery >= 30 && battery < 40) {
      symbolSize = 28;
      obj = {
        color: 'rgba(245, 157, 17, 1)',
        shadowBlur: 10,
        shadowColor: 'rgba(245, 157, 17, 0.5)',
      };
    } else if (battery >= 40 && battery < 50) {
      symbolSize = 24;
      obj = {
        color: 'rgba(231, 179, 21, 1)',
        shadowBlur: 10,
        shadowColor: 'rgba(231, 179, 21, 0.5)',
      };
    } else if (battery >= 50 && battery < 60) {
      symbolSize = 20;
      obj = {
        color: 'rgba(231, 209, 20, 1)',
        shadowBlur: 10,
        shadowColor: 'rgba(231, 209, 20, 0.5)',
      };
    } else if (battery >= 60 && battery < 70) {
      symbolSize = 18;
      obj = {
        color: 'rgba(195, 219, 27, 1)',
        shadowBlur: 10,
        shadowColor: 'rgba(195, 219, 27, 1)',
      };
    } else if (battery >= 70 && battery < 80) {
      symbolSize = 16;
      obj = {
        color: 'rgba(155, 209, 23, 1)',
        shadowBlur: 10,
        shadowColor: 'rgba(155, 209, 23, 0.5)',
      };
    } else if (battery >= 80 && battery < 90) {
      symbolSize = 14;
      obj = {
        color: 'rgba(93, 209, 23, 1)',
        shadowBlur: 10,
        shadowColor: 'rgba(93, 209, 23, 0.5)',
      };
    } else if (battery >= 90 && battery <= 100) {
      symbolSize = 12;
      obj = {
        color: 'rgba(23, 209, 75, 1)',
        shadowBlur: 10,
        shadowColor: 'rgba(23, 209, 75, 0.5)',
      };
    }

    return {
      name: item.cId,
      value: [index + 1, battery],
      symbolSize: nowSize(symbolSize),
      tipData: {
        cId: single ? cId.split('-')[1] : cId,
        battery,
      },
      itemStyle: obj,
    };
  });
  return {
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(3,29,63,0.6)',
      borderColor: '#0DC8FE',
      borderWidth: '1',
      padding: nowSize(5),
      textStyle: {
        color: '#fff',
        fontSize: nowSize(14),
      },
      extraCssText: 'border-radius: 0; padding: 10px;',
      formatter: params => {
        return `
        <div style="border-bottom: 1px solid #0DC8FE; color: #0DC8FE; padding: 4px 10px; font-battery: 16px; font-weight: bolder;">${globalVueI18n.translate(
          'screen.numberOfPositionBattery',
          { num: params.data.tipData.cId },
        )}</div>
        <div style="padding: 2px 10px; font-battery: 14px;">${globalVueI18n.translate(
          'screen.RemainingChargeOfBattery',
        )}: ${params.data.tipData.battery}%</div>
      `;
      },
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
      },
      position: function(pos, params, dom, rect, battery) {
        // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
        var obj = { top: 60 };
        obj[['left', 'right'][+(pos[0] < battery.viewSize[0] / 2)]] = 35;
        return obj;
      },
    },
    grid: {
      x: 50,
      y: 30,
      x2: 20,
      y2: 30,
    },
    xAxis: {
      show: false,
    },
    yAxis: {
      inverse: true,
      splitLine: {
        show: true,
        lineStyle: {
          show: true,
          color: ['#1c1e32'],
        },
      },
      type: 'value',
      // 隐藏刻度线
      max: 100,
      min: 0,
      interval: 10, // 间隔: 10,
      splitNumber: 10,
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
        },
      },
      axisLabel: {
        color: '#ffffff',
        fontSize: nowSize(12),
        formatter: '{a|{value}}',
        width: 20,
        rich: {
          a: {
            align: 'left',
          },
        },
      },
    },
    series: [
      {
        data,
        type: 'scatter',
      },
    ],
  };
};
@Component({
  mixins: [resize],
})
export default class BatteryLevel extends Vue {
  @Prop({ type: String, default: 'BatteryLevel' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  /** @name 单个安监终端 */
  @Prop({ type: Boolean, default: false }) single;
  mounted() {
    if (this.data && this.data.length) this.initChart();
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get isNoData() {
    if (
      !this.data ||
      !this.data.length ||
      !this.data.filter(e => e.battery >= 0).length
    ) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    if (!this.chart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = scatterOptions(this.data, this.$size, this.single);
    this.chart.setOption(options);
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.chartBox {
  width: 100%;
}
</style>
