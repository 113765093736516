import dayjs from 'dayjs';

//距离上次提升天数-数据模拟
export function mockPrevHighBuild(projectInfo) {
  let dataList = [];
  let pId = projectInfo.projectId
    .split('')
    .reverse()
    .join('');
  let builds = projectInfo.buildingList.filter(item => {
    return item.climbProcess === 'DEVICE_BIND';
  });
  for (let i = 0; i < builds.length; i++) {
    let days = 30;
    for (let count = 0; count < 4; count++) {
      days += Number(pId.charAt(i + count));
    }
    dataList.push({
      name: builds[i].buildingName,
      value: days,
      // nowDate: 1647590145475,
      // predictionDate: '',
    });
  }
  return dataList;
}

//楼栋安全防护平台平均提升天数-数据模拟
export function mockAverageDays(projectInfo) {
  let dataList = [];
  let pId = projectInfo.projectId
    .split('')
    .reverse()
    .join('');
  let builds = projectInfo.buildingList;
  for (let i = 0; i < builds.length; i++) {
    let days = 7 + Number(pId.charAt(i));
    dataList.push({
      name: builds[i].buildingName,
      value: days,
    });
  }
  return dataList;
}

/** @name 项目安全指数 */
export function mockProjectSafeIndex(projectId) {
  const random = (str = '') => {
    if (str.length < 20) {
      return random(str + str);
    } else {
      return str.slice(0, 20);
    }
  };
  const randomPid = random(projectId);
  const array = randomPid
    .split('')
    .reverse()
    .map(v => (v === '0' ? '1' : v));
  const result = [];
  let sum = 0;
  for (let i = 0; i < array.length; i++) {
    sum += +array[i];
    let timeStamp = dayjs(Date.now()).subtract(sum + 1, 'd');
    let next = array[i + 1] ? +array[i + 1] : 0;
    result.push({
      safeIndex: 99 - (next * 10 + +array[i]) / 100,
      timeStamp: timeStamp.valueOf(),
      time: timeStamp.format('MM/DD'),
    });
  }
  result.sort((a, b) => a.timeStamp - b.timeStamp);
  return result;
}
