<template>
  <div :class="$style.container">
    <no-data-chart v-if="isNoData" />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { globalVueI18n } from '@triascloud/i18n';
import resize from '@/utils/resize';

const dataZoom = [
  {
    type: 'slider',
    show: true,
    start: 0,
    end: 100,
    xAxisIndex: [0],
    height: 8, // 组件高度
    left: 40, // 左边的距离
    right: 10, // 右边的距离
    bottom: 0, // 右边的距离
    handleColor: 'transparen',
    fillerColor: '#075D82',
    borderColor: '#05314E',
    backgroundColor: '#05314E',
    handleStyle: {
      borderColor: '#cacaca',
      borderWidth: '0',
      shadowBlur: 0,
      backgroundColor: '#f8f8f8',
    },
    showDataShadow: false, // 是否显示数据阴影 默认auto
    showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
    handleIcon:
      'M-292,322.2c-3.2,0-6.4-0.6-9.3-1.9c-2.9-1.2-5.4-2.9-7.6-5.1s-3.9-4.8-5.1-7.6c-1.3-3-1.9-6.1-1.9-9.3c0-3.2,0.6-6.4,1.9-9.3c1.2-2.9,2.9-5.4,5.1-7.6s4.8-3.9,7.6-5.1c3-1.3,6.1-1.9,9.3-1.9c3.2,0,6.4,0.6,9.3,1.9c2.9,1.2,5.4,2.9,7.6,5.1s3.9,4.8,5.1,7.6c1.3,3,1.9,6.1,1.9,9.3c0,3.2-0.6,6.4-1.9,9.3c-1.2,2.9-2.9,5.4-5.1,7.6s-4.8,3.9-7.6,5.1C-285.6,321.5-288.8,322.2-292,322.2z',
    filterMode: 'filter',
  },
  {
    type: 'inside',
    show: true,
    xAxisIndex: [0],
    start: 1,
    end: 10,
    zoomOnMouseWheel: false,
  },
];
const lineOptions = (data, nowSize, single) => {
  const category = single
    ? data.map(e => e.cId.split('-')[1])
    : data.map(e => e.cId);
  const values = data.map(e => e.gravity);
  let max = Math.max(...values);
  max += 10 - (max % 10);
  if (data.length > 8) {
    dataZoom[0].show = true;
    dataZoom[0]['end'] = 100 * (8 / category.length);
  } else {
    dataZoom[0].show = false;
  }
  let dataArr = data.map(item => {
    return { value: item.gravity, time: item.updateTime };
  });
  /** @name borderRadius */
  const br = nowSize(7);
  return {
    dataZoom,
    backgroundColor: '',
    grid: {
      x: 50,
      y: 30,
      x2: 20,
      y2: 40,
    },
    tooltip: {
      backgroundColor: 'rgba(3,29,63,0.6)', // 通过设置rgba调节背景颜色与透明度
      color: '#fff',
      borderWidth: '1',
      borderColor: '#0DC8FE',
      padding: nowSize(5),
      textStyle: {
        color: '#fff',
        fontSize: nowSize(14),
      },
      formatter: function(params) {
        let str = `${globalVueI18n.translate('screen.unitX', {
          num: params.name,
        })}：${params.value +
          ' ' +
          globalVueI18n.translate('screen.ton')}<br/>${globalVueI18n.translate(
          'screen.ReportingTime',
        )}：${params.data.time}`;
        if (params.name === '安全临界值') {
          str = `${params.name}: ${params.value}吨`;
        }
        return str;
      },
      extraCssText: 'border-radius: 0; padding: 10px;',
    },
    xAxis: {
      data: category,
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
        },
      },
      axisLabel: {
        interval: 0,
        fontSize: nowSize(12),
        formatter: function(params) {
          if (params.length > 4) {
            return params.substring(0, 3) + '..';
          } else {
            return params;
          }
        },
      },
    },
    yAxis: {
      min: 0,
      max,
      interval: max / 5,
      splitLine: {
        show: true,
        lineStyle: {
          show: true,
          color: ['#1c1e32'],
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
        formatter: function(value) {
          return `{a|${value}吨}`;
        },
        width: 20,
        rich: {
          a: {
            align: 'left',
          },
        },
      },
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: true,
          color: '#b8b8bb',
        },
      },
      splitNumber: 5,
      splitArea: { show: false },
      label: {
        color: 'rgba(255, 255, 255, 0.5)',
      },
    },
    series: [
      {
        type: 'bar',
        barWidth: nowSize(18),
        barGap: '30%',
        label: {
          normal: {
            show: true,
            position: 'top',
            formatter: '{c}',
            color: '#fff',
            textBorderColor: 'transparent',
          },
        },
        itemStyle: {
          normal: {
            color: function(params) {
              let val = params.value;
              if (val >= 0 && val < 2) {
                return '#FFC756';
              } else if (val >= 2 && val < 5) {
                return '#F9713D';
              } else {
                return '#E82E2A';
              }
            },
            label: {
              textStyle: {
                fontSize: nowSize(14),
              },
            },
            barBorderRadius: [br, br, br, br],
          },
          emphasis: {
            barBorderRadius: [br, br, br, br],
          },
        },
        data: dataArr, //values,
      },
      // {
      //   name: 'Temperature',
      //   type: 'line',
      //   data: [],
      //   color: '#ff7070',
      //   markLine: {
      //     label: { show: false },
      //     lineStyle: {
      //       color: '#ff7070',
      //       opacity: 0.5,
      //     },
      //     data: [{ name: '安全临界值', yAxis: 6 }],
      //   },
      // },
    ],
  };
};
@Component({
  mixins: [resize],
})
export default class GravityLeft extends Vue {
  @Prop({ type: String, default: 'GravityLeft' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: () => [] }) data;
  /** @name 单个安监终端 */
  @Prop({ type: Boolean, default: false }) single;

  isNoData = false;
  firstFlag = true;
  @Watch('data', { immediate: true, deep: true })
  changeData(newVal, oldVal) {
    if (!newVal || !newVal.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      this.isNoData = true;
    } else {
      if (this.firstFlag) {
        this.firstFlag = false;
        this.$nextTick(this.initChart);
      }
      if (newVal && oldVal) {
        const s1 = JSON.stringify(newVal);
        const s2 = JSON.stringify(oldVal);
        if (s1 !== s2) {
          this.$nextTick(this.initChart);
        }
      }
      this.isNoData = false;
    }
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  initChart() {
    if (!this.chart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = lineOptions(this.data, this.$size, this.single);
    this.chart && this.chart.clear();
    this.chart.setOption(options, true);
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
}
</style>
