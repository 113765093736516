<template>
  <div :class="$style.infoWrap">
    <div :class="$style.infoItem" v-if="noVisitorMode">
      <span :class="$style.label">{{ $t('screen.generalContractors') }}: </span>
      <Ellipsis
        :class="$style.value"
        :value="data.contractorUnit"
        :styleObject="{
          fontWeight: '700',
        }"
      />
    </div>
    <div :class="$style.infoItem" v-if="noVisitorMode && rootData.epcPrincipal">
      <span :class="$style.label"
        >{{ $t('screen.generalContractorInCharge') }}:
      </span>
      <Ellipsis :class="$style.value" :value="rootData.epcPrincipal" />
    </div>
    <div :class="$style.infoItem" v-if="noVisitorMode && rootData.epcPhone">
      <span :class="$style.label"
        >{{ $t('screen.generalContractorPhone') }}:
      </span>
      <Ellipsis :class="$style.value" :value="rootData.epcPhone" />
    </div>
    <div :class="$style.infoItem">
      <span :class="$style.label"> {{ $t('screen.projectNo') }}: </span>
      <Ellipsis :class="$style.value" :value="data.projectSerial" />
    </div>
    <div :class="$style.infoItem">
      <span :class="$style.label"> {{ $t('screen.projectTypes') }}: </span>
      <Ellipsis :class="$style.value" :value="rootData.projectType" />
    </div>
    <div :class="$style.infoItem" v-if="noVisitorMode">
      <span :class="$style.label"> {{ $t('screen.contractNumber') }}: </span>
      <Ellipsis :class="$style.value" :value="rootData.contractSerial" />
    </div>
    <div :class="$style.infoItem">
      <span :class="$style.label">{{ $t('screen.count.buildings') }}: </span>
      <Ellipsis :class="$style.value" :value="buildingCount" nullValue="0" />
    </div>
    <div :class="$style.infoItem">
      <span :class="$style.label">{{ $t('screen.totalCountDevice') }}: </span>
      <Ellipsis :class="$style.value" :value="data.seatNumber" />
    </div>
    <a
      :href="
        `https://uri.amap.com/marker?position=${rootData.longitude},${rootData.latitude}&name=${rootData.name}&src=mypage&coordinate=gaode&callnative=0`
      "
      target="_black"
    >
      <div :class="$style.infoItemWidth">
        <span :class="$style.label" @click.prevent
          >{{ $t('electricBox.mg.projectAddress') }}:
        </span>
        <Ellipsis :class="$style.value" :value="formatAddress(data)" />
      </div>
    </a>
  </div>
</template>

<script>
import { Component, Prop, Vue, Inject } from 'vue-property-decorator';
import Ellipsis from '@/components/ellipsis';
@Component({
  components: {
    Ellipsis,
  },
})
export default class ProjectBaseInfo extends Vue {
  @Prop({ type: Object, default: () => {} }) data;
  @Prop({ type: Object, default: () => {} }) rootData;
  @Prop({ type: Number, default: 0 }) buildingCount;
  @Inject('noVisitorMode') noVisitorMode;

  formatAddress() {
    if (this.data.projectLocation) {
      return `${this.rootData.province}${this.rootData.city}${this.rootData.county}${this.data.projectLocation}`;
    } else {
      return '无';
    }
  }
}
</script>

<style lang="less" module>
.infoWrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow: hidden;
  padding: 0.3rem;
  padding-bottom: 0;
  .infoItem {
    min-width: 50%;
    max-width: 100%;
    font-size: 0.16rem;
    text-align: center;
    position: relative;
    text-align: left;
    margin-bottom: 0.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #e5e5e6;
    .label {
      float: left;
      color: #a4a5a8;
      cursor: default;
      margin-right: 0.2rem;
    }
    .value {
      overflow: hidden;
      color: #e5e5e6;
    }
  }
  .infoItemWidth {
    min-width: 50%;
    max-width: 100%;
    font-size: 0.16rem;
    text-align: center;
    position: relative;
    text-align: left;
    margin-bottom: 0.2rem;
    display: flex;
    color: #e5e5e6;
    .label {
      float: left;
      color: #a4a5a8;
      cursor: default;
      margin-right: 0.2rem;
    }
    .value {
      flex: 1;
      color: #e5e5e6;
      overflow: hidden;
      max-height: 0.8rem;
    }
  }
}
</style>
