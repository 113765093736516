<template>
  <div :class="$style['header-wrap']">
    <div :class="$style['header-title']">
      <router-link
        v-if="!projectVisitor"
        :to="formUrl"
        class="TC tc-icon-fanhui2"
        :class="$style['back']"
      ></router-link>
      <span :class="$style.name">
        {{ rollData.name }}
      </span>
    </div>
    <roll-tab
      :tabList="tabList"
      v-if="tabList && tabList.length"
      @select="selectHandle"
      ref="rollTab"
      :itemMaxWidth="1.2"
    ></roll-tab>
    <screen-icon-info
      :noVisitorMode="noVisitorMode"
      :weatherData="weatherData"
      :deviceInfo="deviceInfo"
      :attentionData="myAttention"
      :recommendData="myRecommend"
      @share="$emit('share')"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch, Inject } from 'vue-property-decorator';
import RollTab from './screen-header/roll-tab';
import ScreenIconInfo from './screen-header/screen-icon-info';

@Component({
  components: {
    RollTab,
    ScreenIconInfo,
  },
})
export default class ScreenHeader extends Vue {
  @Inject('projectVisitor') projectVisitor;
  @Prop({ type: Object, default: {} }) rollData;
  @Prop({ type: Object, default: {} }) weatherData;
  @Prop({ type: Boolean, default: true }) noVisitorMode;
  @Prop({ type: Object, default: {} }) deviceInfo;
  get formUrl() {
    return this.$store.state.screen.backRouterLink;
  }

  tabList = [];
  myAttention = {};
  myRecommend = {};
  @Watch('rollData', { deep: true, immediate: true })
  rollDataChange(value) {
    if (value.buildingList) {
      const list = value.buildingList.map(el => {
        return {
          text: el.buildingName,
          value: el.pkBuildingId,
          climbProcess: el.climbProcess,
          quitTime: el.quitTime,
        };
      });
      list.unshift({
        climbProcess: 'NORMAL',
        quitTime: undefined,
        text: this.$t('screen.overviews'),
        value: '',
      });
      this.tabList = list;
    }
    const { isAuthority, isAttention, attentionId, recommend } = value;
    this.myAttention = {
      isAuthority,
      isAttention,
      attentionId,
    };
    this.myRecommend = {
      isRecommend: recommend,
    };
  }

  selectHandle(item) {
    this.$emit('select', item);
  }
}
</script>

<style lang="less" module>
.header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.4rem;
  position: relative;
  .header-title {
    font-size: 0.22rem;
    font-weight: bold;
    color: #05d3ff;
    width: 4rem;
    display: flex;
    align-items: center;
  }
  .back {
    font-size: 0.34rem;
    color: #05d3ff;
    display: block;
    margin-right: 0.2rem;
    line-height: 0.8rem;
  }
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 0.2rem;
    line-height: 0.34rem;
    word-break: break-all;
  }
}
</style>
