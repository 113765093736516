import request from '../request';

const serviceName = '/things/project';

/**
 * @name 添加-删除关注
 */
export function addOrDeleteAttention(data) {
  return request(`${serviceName}/attention-project-screen`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: data,
  });
}

/**
 * @name 添加-删除推荐
 */
export function addOrDeleteRecommend(data) {
  return request(`${serviceName}/recommend`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: data,
  });
}
