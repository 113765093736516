<template>
  <div ref="wrapper">
    <div v-if="reportList.length > 0" :class="$style.tableHead">
      <div :class="$style.name">
        <span>报告名称</span>
      </div>
      <div :class="$style.score">
        <span>评分</span>
        <a-icon
          @click="openScoreTips"
          :class="$style.scoreIcon"
          type="question-circle"
        />
      </div>
      <div :class="$style.operation">
        <span>操作</span>
      </div>
    </div>
    <div
      v-for="(item, idx) in reportList"
      :key="idx"
      :class="[$style.tableHead, $style.tableItem]"
    >
      <div :class="$style.name">
        <span>{{ item.autoReport + '--' }}</span>
        <span>{{ formateTimeStr(item.reportDate) }}</span>
      </div>
      <div :class="$style.score">
        <span>{{ item.score }}</span>
      </div>
      <div :class="[$style.operation, $style.share]" @click="shareReport(item)">
        <span>分享</span>
      </div>
    </div>
    <div v-if="reportList.length === 0 && !hasMore" :class="$style.detailWarp">
      <div>
        <div :class="$style.comingSoon" />
        <p :class="$style.bottomText">当前楼栋暂无报告，请耐心等待！</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  getReportList,
  getReportListByShare,
} from '@/services/things/building.js';
import dayjs from 'dayjs';
import { createModal, createFormModal } from '@triascloud/x-components';
import shareForm from './share-form';
import shareBox from '../screen-header/share-box';
import createLoading from '@/components/loading';
import { ssoClient } from '@triascloud/sso-client';
import ScoreTips from '@/views/iot/general-view/safe-operation/score-tips.vue';

@Component()
export default class PromoteDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) item;

  getTimestamp(t, format) {
    return dayjs(dayjs(t).format('YYYY-MM-DD') + format).valueOf();
  }
  loading = undefined;
  mounted() {
    try {
      this.$nextTick(() => {
        this.loading = createLoading({
          boxEl: this.$refs.wrapper.parentNode,
          inline: true,
        });
      });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  @Watch('item', { immediate: true })
  changeData(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.item = newVal;
      if (newVal) {
        this.getList();
      }
    }
  }

  formateTimeStr(t) {
    if (t) {
      return dayjs(t).format('YYYYMMDD');
    } else {
      return '--';
    }
  }

  reportList = [];
  hasMore = true;
  async getList() {
    try {
      let interfaceList = '';
      if (ssoClient.status) {
        interfaceList = getReportList;
      } else {
        interfaceList = getReportListByShare;
      }
      const date = this.item.date;
      const startT = this.getTimestamp(date, ' 00:00:00');
      const endT = this.getTimestamp(date, ' 23:59:59');
      const id = this.item.buildingID;
      const reportName = this.item.reportName;
      const params = {
        buildingId: id,
        startTime: startT,
        endTime: endT,
        scoreSort: 1,
        pageNum: 1,
        pageSize: 10,
      };
      const { records } = await interfaceList(params);
      this.loading && this.loading.close();
      this.reportList = [];
      if (!records.length) {
        this.hasMore = false;
        return;
      } else {
        this.hasMore = true;
      }
      records.forEach(item => {
        const o = {};
        o.autoReport = item.autoReport
          ? '操作报告（自动）'
          : '操作报告（手动）';
        o.reportDate = item.reportDate;
        o.score = item.score;
        o.pkId = item.pkId;
        o.reportName = reportName;
        this.reportList.push(o);
      });
    } catch (err) {
      this.loading && this.loading.close();
      this.hasMore = false;
    }
  }
  get rules() {
    return {
      password: [
        {
          required: this.setPassword,
          message: '请设置密码',
          trigger: 'change',
        },
        {
          len: 4,
          message: '请设置4位数密码',
          trigger: 'change',
        },
      ],
    };
  }
  async shareReport(item) {
    const name = item.autoReport + '--' + this.formateTimeStr(item.reportDate);
    const data = {
      reportId: item.pkId,
      projectName: item.reportName + ' ' + name,
    };
    const shareInfo = await createFormModal(() => <shareForm data={data} />, {
      title: '楼栋提升报告',
      okText: '生成链接',
      className: this.$style.shareModalForm,
      width: `500px`,
    });
    const modal = createModal(
      () => (
        <shareBox shareInfo={shareInfo} onClose={() => modal.handleClose()} />
      ),
      {
        title: '楼栋提升报告',
        className: this.$style.shareModal,
        width: `500px`,
      },
    );
  }
  openScoreTips() {
    createModal(() => <ScoreTips showBack={true} />, {
      title: '报告评分规则',
      className: this.$style.shareModal,
      width: 500,
    });
  }
}
</script>

<style lang="less" module>
@fontColor: #05d3ff;
.detailWarp {
  color: #fff;
  text-align: center;
  margin-top: 25px;
  font-size: 14px;
  .comingSoon {
    width: 128px;
    height: 90px;
    margin: 0 auto 16px;
    background-image: url('~@/assets/images/screen/commonSoon.png');
  }
  .bottomText {
    font-size: 14px;
  }
}
.tableHead {
  display: flex;
  padding: 0 20px;
  font-size: 14px;
  background-color: #1d3453;
  height: 30px;
  align-items: center;
  .name {
    flex: 3;
  }
  .score {
    flex: 1;
    .scoreIcon {
      cursor: pointer;
      margin-left: 5px;
    }
  }
  .operation {
    flex: 1;
  }
}
.tableItem {
  background-color: transparent;
  cursor: default;
  &:hover {
    background-color: #1d3453;
    color: white;
  }
}
.share {
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
.shareCode {
  position: absolute;
  height: 100%;
  width: 246px;
  z-index: 1;
  left: calc(100% + 10px);
  top: 0;
  padding: 20px;
  background-color: #041e40;
  box-shadow: 1px 1px 10px rgb(8 1 3 / 8%);
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .code {
    width: 206px;
    height: 206px;
    margin-bottom: 10px;
  }
  .download {
    height: 36px;
    width: 206px;
    //background-color: #032f49;
    //border-color: #05d3ff;
  }
}
.shareOptions {
  font-size: 14px;
}
.shareModal,
.shareModalForm {
  :global {
    .ant-modal-body {
      padding: 30px 20px 20px;
      box-sizing: border-box;
      height: 214px;
      overflow-y: unset;
      margin-bottom: unset;
    }
    .ant-modal-content {
      background-color: #031d3f;
    }
    .ant-modal-header {
      color: @fontColor;
      background: #1d3453;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
    }
    .ant-modal-title {
      color: @fontColor;
      font-weight: 600;
      text-align: center;
    }
    .ant-modal-close-x {
      width: 40px;
      height: 50px;
      line-height: 50px;
      color: #5bd1fa50;
      font-size: 16px;
    }
    .ant-modal-close-x .anticon {
      vertical-align: unset;
    }
    .ant-modal-footer {
      .ant-btn {
        border-color: @fontColor!important;
        color: @fontColor!important;
      }
      .ant-btn-primary {
        background-color: @fontColor;
        color: #031d3f !important;
      }
    }
    .ant-btn:hover {
      background-color: transparent;
    }
    .ant-btn:hover.ant-btn-primary {
      background-color: @fontColor;
    }
  }
}
</style>

<!--<style lang="less" scoped>-->
<!--::v-deep {-->
<!--  .ant-radio,-->
<!--  .ant-radio-group,-->
<!--  .ant-radio-wrapper {-->
<!--    color: #06d3ff;-->
<!--  }-->
<!--}-->
<!--</style>-->
