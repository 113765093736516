<template>
  <div :class="$style.monitor_screenshot">
    <div :class="$style.screenshot_title">
      <span>{{ $t('screen.screenshots') }}</span>
      <span @click="addScreenshot" :class="$style.screenshotIcon">
        <a-icon type="plus" />&nbsp;{{ $t('screen.upload') }}
      </span>
    </div>
    <div
      :class="$style.screenshot_list"
      v-if="screenshotList && screenshotList.length"
      v-infinite-scroll="getScreenshotList"
      ref="shotBox"
      :infinite-scroll-disabled="isNextPageLoadinng"
    >
      <div
        :class="[
          $style.screenshot_timeline,
          item.alarmMessageType === 'NO_HELMET' ? $style.warn : '',
        ]"
        v-for="(item, index) in screenshotList"
        :key="index"
        ref="shot"
        @click="changeShot(index)"
      >
        <div :class="$style.line">
          <div :class="$style.front_line"></div>
          <div :class="$style.point"></div>
          <div :class="$style.back_line"></div>
        </div>
        <div :class="$style.screenshot_content">
          <span
            class="TC tc-icon-gaojing"
            :class="$style.alarm_icon"
            @click="alarmIconHandle(index, item)"
          ></span>
          <div
            :class="[
              $style.screenShotBox,
              item.alarmMessageType === 'MANUAL' ? $style.hover : '',
            ]"
          >
            <div :class="$style.screenShotBoxMark">
              <span
                class="TC tc-icon-shanchu"
                @click.stop="handleDeleteScreenshot(item)"
                :class="$style.icon"
              ></span>
            </div>
            <img
              :src="item.screenshotUrl"
              :class="currentShot === index ? $style.current_img : ''"
            />
          </div>
          <div :class="$style.screenshot_desc">
            <span>{{ item.screenshotTime }}</span>
            <span>{{ actionEnum[item.alarmMessageType] }}</span>
          </div>
        </div>
      </div>
    </div>
    <no-data-chart v-else />
  </div>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import createModal from '@/views/iot/screen/components/message/modal.js';
import DeleteImage from './delete-image.vue';
import UploadImage from './upload-image.vue';
import { delteMonitorScreenshot } from '@/services/monitor/client';
import dayjs from 'dayjs';
import { getScreenshot } from '@/services/monitor/client';
import { formatPictureUrl } from './monitor.js';
import { monitorModule } from '@/enum/store.js';
@Component()
export default class MonitorPicture extends Vue {
  @monitorModule.State cameraId;

  alarmFnDialog(index, item) {
    this.changeShot(index);
    this.$emit('warn-form', item);
  }

  actionEnum = {
    MOTION_DETECT: '画面发生变化',
    FACE_RECOGNITION: '人脸抓拍',
    NO_HELMET: '没戴安全帽',
    MANUAL: '人工添加截图',
  };

  addScreenshot() {
    createModal(
      () => (
        <UploadImage
          ref={'UploadImageRef'}
          cameraId={this.cameraId}
          onSuccess={() => this.resetMonitor()}
        />
      ),
      {
        title: '添加人工截图',
        closeTxt: '取消',
        okTxt: '确认',
        ok: () => {
          this.$refs.UploadImageRef &&
            this.$refs.UploadImageRef.updateCameraNameApi();
        },
      },
    );
  }

  deleteItem = {};
  handleDeleteScreenshot(item) {
    this.deleteItem = item;
    createModal(
      () => (
        <DeleteImage
          ref={'DeleteImageRef'}
          onSuccess={() => this.deleteImageItem()}
        />
      ),
      {
        title: '操作提示',
        className: this.$style.warningModal,
        width: `${this.$size(460)}px`,
        closeTxt: '取消',
        okTxt: '确定',
        ok: () => {
          this.$refs.DeleteImageRef && this.$refs.DeleteImageRef.submit();
        },
      },
    );
  }

  async deleteImageItem() {
    try {
      await delteMonitorScreenshot(this.deleteItem.id);
      this.$message.success('截图已删除');
      this.resetMonitor();
    } catch (error) {
      this.$message.error(error.message);
    }
  }

  @monitorModule.State currentShot;
  @monitorModule.Mutation updateCurrentShot;
  changeShot(val) {
    this.updateCurrentShot(val);
    this.updateShowShot(true);
    this.gotoCarousel(val);
  }

  gotoCarousel(val) {
    this.$emit('change-carousel', val);
  }

  screenshotQuery = {
    pageNum: 0,
    pageSize: 10,
  };
  isNextPageLoadinng = false;
  screenshotList = [];
  /**
   * @name 获取截图列表
   */
  async getScreenshotList() {
    if (!this.isNextPageLoadinng) {
      this.screenshotQuery.pageNum++;
      this.isNextPageLoadinng = true;
    }
    const data = await getScreenshot({
      ...this.screenshotQuery,
      cameraId: this.cameraId,
    });
    this.isNextPageLoadinng = false;
    const pictures = await formatPictureUrl(data.records);
    this.screenshotList = [
      ...this.screenshotList,
      ...pictures.map(e => ({
        ...e,
        screenshotTime: dayjs(e.screenshotTime).format('YYYY-MM-DD HH:mm:ss'),
      })),
    ];

    if (this.screenshotList.length === 0) {
      this.updateShowShot(false);
    }

    this.regAlarmPicture();
  }
  /** @name 匹配路由预警的图片 */
  regAlarmPicture() {
    if (this.$route.query.p) {
      const result = this.screenshotList.findIndex(
        v => v.id === this.$route.query.p,
      );
      if (result !== -1) {
        this.$nextTick(() => {
          this.updateShowShot(true);
          this.gotoCarousel(result);
        });
      }
    }
  }

  @monitorModule.Mutation updateShotList;
  @monitorModule.Mutation updateShowShot;

  @Watch('screenshotList')
  onListChange(value) {
    this.updateShotList(value);
  }
  resetMonitor() {
    this.screenshotQuery.pageNum = 0;
    this.screenshotList = [];
    this.getScreenshotList();
  }

  // refresh(value) {
  //   if (this.cameraId !== value.cameraId) return false;
  //   // TODO screenshotTime 这个时间，需要从后端获取
  //   this.screenshotList.unshift({
  //     ...value,
  //     screenshotTime: dayjs(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
  //   });
  //   this.$nextTick(() => {
  //     const computed = this.$refs.shot[0].offsetHeight;
  //     const shotBox = this.$refs.shotBox;
  //     shotBox.scrollTo(0, -(shotBox.scrollTop + computed));
  //   });
  // }
}
</script>
<style lang="less" module>
@import './monitor.less';
.monitor_screenshot {
  width: 20%;
  overflow: hidden;
  // border: 1px solid @lightLineColor;
  background: @boxBg;
  background-repeat: no-repeat;
  background-size: 0.02rem 0.2rem, 0.2rem 0.02rem;
  // border: 1px solid @lightLineColor;
  box-shadow: @boxshadow;
  padding-left: 0.1rem;
  .screenshot_title {
    padding: 0 2em;
    height: 0.4rem;
    line-height: 0.4rem;
    margin-bottom: 0.1rem;
    font-size: 0.14rem;
    display: flex;
    justify-content: space-between;
    .screenshotIcon {
      cursor: pointer;
    }
  }
  .shot_type_select {
    width: 1.1rem;
  }
  .screenshot_list {
    max-height: calc(100% - 0.5rem);
    overflow-y: auto;
    padding: 0 0.25rem 0 0.1rem;
  }
  .screenshot_timeline {
    display: flex;
    height: 2.2rem;
    justify-content: space-between;
    &.warn {
      .point {
        background-color: #fe3030 !important;
        border-color: #4e1221 !important;
      }
      .current_img {
        border-color: #fe3030 !important ;
      }
      .screenshot_desc {
        span:last-child {
          color: #fe3030 !important;
        }
      }
      .screenshot_content {
        &::after {
          position: absolute;
          top: 0.2rem;
          right: 0.21rem;
          width: 0.22rem;
          height: 0.22rem;
          background-color: #fff;
          content: '';
          z-index: 1;
          border-radius: 50%;
        }
        .alarm_icon {
          display: inline-block;
        }
      }
    }
    .line {
      width: 0.12rem;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      margin-right: 0.2rem;
      .front_line {
        background-color: #032741;
        width: 0.01rem;
        height: calc(40% - 0.2rem);
        margin-bottom: 0.2rem;
      }
      .point {
        width: 0.25rem;
        height: 0.25rem;
        border-radius: 50%;
        background-color: #05d3ff;
        border: 0.06rem solid #034360;
      }
      .back_line {
        flex: 1;
        background-color: #032741;
        width: 0.01rem;
        margin-top: 0.2rem;
      }
    }
    &:first-child {
      .front_line {
        background-color: transparent;
      }
    }
    &:last-child {
      .back_line {
        background-color: transparent;
      }
    }
    .screenshot_content {
      position: relative;
      flex: 1;
      .alarm_icon {
        display: none;
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
        font-size: 0.26rem;
        color: #fe3030;
        z-index: 2;
      }
      .hover:hover {
        .screenShotBoxMark {
          position: absolute;
          z-index: 10;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          .icon {
            font-size: 0.36rem;
            cursor: pointer;
          }
        }
      }
      .screenShotBox {
        position: relative;
        width: 100%;
        height: 1.75rem;
        .screenShotBoxMark {
          display: none;
        }
      }
      img {
        border: 3px solid transparent;
        width: 100%;
        height: 1.75rem;
        border-radius: 0.1rem;
        &.current_img {
          border-color: @lineColor;
        }
      }
      .screenshot_desc {
        display: flex;
        justify-content: space-between;
        font-size: 0.12rem;
        margin-top: 0.08rem;
        width: 100%;
        span:first-child {
          width: 2rem;
        }
        span:last-child {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
}
</style>
