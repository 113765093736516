<template>
  <div :class="$style.overview_wrap">
    <div :class="$style.projress" v-if="noVisitorMode && hasProgress">
      <ProgressEvent :data="timerShaftData" />
    </div>
    <main :class="noVisitorMode && hasProgress ? '' : $style.visitor">
      <div :class="$style.chart">
        <div :class="$style.col_wrap">
          <div :class="$style.col_item">
            <!-- 项目基本信息 -->
            <base-info
              :countData="countData"
              :personData="personData"
              :buildingCount="buildingCount"
            />
          </div>
          <div :class="$style.col_item">
            <!-- 7天设备状态 7日预警&故障 电池电量 -->
            <device-warn-battery
              :sevenSenseData="sevenSenseData"
              :errorPowerList="errorPowerList"
              :seatPowerList="seatPowerList"
              :climbList="climbList"
            />
          </div>
        </div>
        <div :class="$style.col_wrap">
          <div :class="$style.col_item">
            <!-- 安全防护平台高度图 -->
            <climbing-height ref="climbingHeightRef" :data="climbHeightData" />
          </div>
          <div :class="$style.col_item">
            <climbing-status
              ref="climbingStatusRef"
              :data="climbCurrentState"
            />
          </div>
        </div>
        <div :class="$style.col_wrap">
          <div :class="$style.col_item">
            <!-- 距离上次提升天数统计图 -->
            <prevHigh-building
              ref="preHighBuildingRef"
              :data="climbLastRiseData"
            />
          </div>
          <div :class="$style.col_item">
            <average ref="averageRef" :data="climbAvgRiseData"></average>
          </div>
        </div>
      </div>
      <div :class="$style.safe_index">
        <safe-index
          ref="safeIndexRef"
          :list="safeTyIndexList"
          @showSafeModal="showSafeModal"
        />
      </div>
    </main>
  </div>
</template>

<script>
import { Component, Vue, Inject, Watch, Prop } from 'vue-property-decorator';
import ProgressEvent from './overflow/progress-event';
import { globalModule } from '@/enum/store.js';
import BaseInfo from './overflow/base-info';
import DeviceWarnBattery from './overflow/device-warn-battery';
import ClimbingHeight from './overflow/charts/climbing-height';
import PrevHighBuilding from './overflow/charts/prevhigh-building';
import ClimbingStatus from './overflow/charts/climbing-status';
import SafeIndex from './overflow/charts/safe-index';
import Average from './overflow/charts/average';
import { isEmptyObject } from '@/utils';
import SafeModal from '@/views/iot/screen/components/common/safe-modal';
import createModal from '@/views/iot/screen/components/message/modal.js';
import dayjs from 'dayjs';
import {
  mockPrevHighBuild,
  mockAverageDays,
  mockProjectSafeIndex,
} from '../mock/mock-overview-data';
/** @name 概览设备信息*/
const PROJECT_DEVICE = 'PROJECT_DEVICE';
/** @name 概览安全防护平台高度图  */
const PROJECT_CLIMB_HEIGHT = 'PROJECT_CLIMB_HEIGHT';
/** @name 概览安全防护平台距离上次提升统计*/
const PROJECT_CLIMB_LAST_RISE_STATISTICS = 'PROJECT_CLIMB_LAST_RISE_STATISTICS';
/** @name 概览安全防护平台平均提升天数统计*/
const PROJECT_CLIMB_AVG_RISE_STATISTICS = 'PROJECT_CLIMB_AVG_RISE_STATISTICS';
/** @name 概览安全防护平台当前状态统计*/
const PROJECT_CLIMB_CURRENT_PROCESS = 'PROJECT_CLIMB_CURRENT_PROCESS';
/** @name 概览项目详情 */
const PROJECT_DETAILS = 'PROJECT_DETAILS';
const keyObject = {
  GRAVITY: '载荷传感器',
  ELECTRIC_CURRENT: '电流传感器',
  STANCE: '倾斜传感器',
  BATTERY: '电池',
  GATEWAY: '网关',
};
@Component({
  components: {
    ProgressEvent,
    BaseInfo,
    DeviceWarnBattery,
    ClimbingHeight,
    PrevHighBuilding,
    Average,
    ClimbingStatus,
    SafeIndex,
  },
})
export default class Overview extends Vue {
  @Inject('noVisitorMode') noVisitorMode;
  @Prop({ type: Number, default: 0 }) buildingCount;
  @globalModule.State mock;
  climbHeightData = [];
  climbLastRiseData = [];
  climbAvgRiseData = [];
  climbCurrentState = [];
  countData = {};
  personData = {};
  timerShaftData = {
    processVO: [],
    isEnd: false,
  };
  safeTyIndexList = [];
  sevenSenseData = [];
  climbList = {
    error: [],
    warn: [],
  };
  wsRequestMap = {};
  errorPowerList = []; // 异常电池图表数据
  seatPowerList = []; // 低电量图表数据
  messageHandle(result) {
    const { event, data } = result;
    switch (event) {
      case PROJECT_CLIMB_HEIGHT:
        this.climbHeightData = data.sort(
          (a, b) => b.climbNumber - a.climbNumber,
        );
        break;
      case PROJECT_CLIMB_LAST_RISE_STATISTICS:
        this.climbLastRiseData = data.sort((a, b) => b.value - a.value);
        break;
      case PROJECT_DEVICE:
        this.distributeProjectDevice(data);
        break;
      case PROJECT_CLIMB_AVG_RISE_STATISTICS:
        this.climbAvgRiseData = data.sort((a, b) => b.value - a.value);
        break;
      case PROJECT_CLIMB_CURRENT_PROCESS:
        this.climbCurrentState = data;
        break;
      case PROJECT_DETAILS:
        {
          const {
            projectProcessDTO,
            projectSerial,
            seatNumber,
            regionName,
            siteManager,
            projectPrincipalName,
            projectPrincipalPhone,
            contractorUnit,
            projectLocation,
          } = data;
          this.countData = {
            projectSerial,
            seatNumber,
            regionName,
            siteManager,
            projectPrincipalName,
            projectPrincipalPhone,
            contractorUnit,
            projectLocation,
          };
          this.personData = data;
          this.timerShaftData = projectProcessDTO;
        }
        break;
    }
    if (this.mock) {
      this.wsRequestMap[event] = this.wsRequestMap[event]
        ? this.wsRequestMap[event] + 1
        : 1;

      if (this.wsRequestMap.PROJECT_DETAILS) {
        if (
          this.climbLastRiseData.length === 0 &&
          ['PROJECT_DETAILS', 'PROJECT_CLIMB_LAST_RISE_STATISTICS'].indexOf(
            event,
          ) > -1 &&
          this.wsRequestMap.PROJECT_DETAILS ===
            this.wsRequestMap.PROJECT_CLIMB_LAST_RISE_STATISTICS
        ) {
          this.climbLastRiseData = mockPrevHighBuild({
            projectId: this.$route.params.projectId,
            ...this.personData,
          });
        }
        if (
          ['PROJECT_DETAILS', 'PROJECT_CLIMB_AVG_RISE_STATISTICS'].indexOf(
            event,
          ) > -1 &&
          this.climbAvgRiseData.length === 0 &&
          this.wsRequestMap.PROJECT_DETAILS ===
            this.wsRequestMap.PROJECT_CLIMB_AVG_RISE_STATISTICS
        ) {
          this.climbAvgRiseData = mockAverageDays({
            projectId: this.$route.params.projectId,
            ...this.personData,
          });
        }
      }
    }
  }
  /**
   * @name 分发
   * @description deviceBinds 设备在线
   */
  distributeProjectDevice(data) {
    const {
      deviceBinds,
      safetyCount,
      deviceStatusCount,
      alarmCount,
      batteryPowers,
      alarm,
    } = data;
    alarm &&
      this.$store.dispatch('screen/getAlarmMessageListByWebsocket', alarm);
    this.handleDeviceOnline(deviceBinds);
    this.handleSafeTyIndex(safetyCount);
    this.handleDeviceStatus(deviceStatusCount);
    this.handleAlarmCount(alarmCount);
    this.handleBattery(batteryPowers);
  }
  /**@name 是否有流程 */
  get hasProgress() {
    return Boolean(
      this.timerShaftData &&
        this.timerShaftData.processVO &&
        this.timerShaftData.processVO.length,
    );
  }
  @Watch('hasProgress', { immediate: true })
  progressChange() {
    setTimeout(() => {
      this.$refs.climbingHeightRef &&
        this.$refs.climbingHeightRef.resizeChart();
      this.$refs.climbingStatusRef &&
        this.$refs.climbingStatusRef.resizeChart();
      this.$refs.preHighBuildingRef &&
        this.$refs.preHighBuildingRef.resizeChart();
      this.$refs.averageRef && this.$refs.averageRef.resizeChart();
      this.$refs.safeIndexRef && this.$refs.safeIndexRef.resizeChart();
    }, 0);
  }
  /** @name 设备在线 */
  handleDeviceOnline(list) {
    let onlineCount = 0;
    let array = [];
    try {
      array = list;
      const ONLINE = 'ONLINE';
      list.forEach(v => {
        if (v.deviceStatus === ONLINE) {
          onlineCount++;
        }
      });
    } catch (error) {
      onlineCount = 0;
      array = [];
    }
    this.$emit('device-online', {
      total: array.length,
      sub: onlineCount,
    });
  }
  /** @name 项目安全指数  */
  handleSafeTyIndex(safeIndex) {
    if (this.mock) {
      this.safeTyIndexList = mockProjectSafeIndex(this.$route.params.projectId);
      return false;
    }
    try {
      if (isEmptyObject(safeIndex)) {
        this.safeTyIndexList = null;
        return;
      }
      this.safeTyIndexList = safeIndex
        .sort((a, b) => a.time - b.time)
        .map(e => ({
          ...e,
          timeStamp: e.time,
          time: dayjs(e.time).format('MM-DD'),
        }));
    } catch (error) {
      this.safeTyIndexList = [];
    }
  }
  /** @name 7天设备状态 */
  handleDeviceStatus(object) {
    try {
      const array = [];
      Object.keys(object).forEach(v => {
        let warnCount = 0;
        let errorCount = 0;
        object[v].forEach(item => {
          if (item.eventType === 'WARN') {
            warnCount++;
          }
          if (item.eventType === 'ERROR') {
            errorCount++;
          }
        });
        const total = warnCount + errorCount;
        const data1 = Math.round((warnCount / total) * 100);
        const data2 = 100 - data1;
        array.push({
          errorCount,
          warnCount,
          name: keyObject[v],
          data: [data1, data2],
        });
      });
      this.sevenSenseData = array;
    } catch (error) {
      this.sevenSenseData = [];
    }
  }
  /** @name 7日预警&故障 */
  handleAlarmCount(object) {
    try {
      let keys = Object.keys(object.ERROR || {});
      let error = keys
        .map(el => {
          return {
            typeStr: object.ERROR[el][0].type,
            countNum: object.ERROR[el].length,
            details: object.ERROR[el],
          };
        })
        .sort((a, b) => b.countNum - a.countNum);
      let keys1 = Object.keys(object.WARN || {});
      let warn = keys1
        .map(el => {
          return {
            typeStr: object.WARN[el][0].type,
            countNum: object.WARN[el].length,
            details: object.WARN[el],
          };
        })
        .sort((a, b) => b.countNum - a.countNum);
      this.climbList = {
        error: error,
        warn: warn,
      };
    } catch (error) {
      this.climbList = {
        error: [],
        warn: [],
      };
    }
  }
  /** @name 电池电量  */
  handleBattery(list) {
    if (!list || !list.length) return;
    let totalCount = 0;
    list.forEach(v => {
      if (v.seatBattery.length) {
        totalCount += v.seatBattery.length;
      }
    });

    let lowTotalCount = 0;
    list.forEach(v => {
      v.seatBattery.forEach(item => {
        if (item.battery !== -1) {
          lowTotalCount++;
        }
      });
    });
    const array = list.map(v => {
      let errorCount = 0;
      let lowCount = 0;
      const exceptions = [];
      const lowPowerSeat = [];
      v.seatBattery.forEach(battery => {
        if (battery.battery === -1) {
          errorCount++;
          exceptions.push(battery.cid);
        }
        if (battery.battery < 20 && battery.battery !== -1) {
          lowCount++;
          lowPowerSeat.push({
            alias: battery.alias,
            seat: battery.cid,
            power: battery.battery,
          });
        }
      });
      return {
        ...v,
        buildingName: v.buildName,
        exceptions: exceptions,
        lowPowerSeat: lowPowerSeat,
        value: errorCount,
        errorCount: errorCount,
        lowCount: lowCount,
        errorPercentage: this.fetchPercentage(totalCount, errorCount),
        seatPercentage: this.fetchPercentage(lowTotalCount, lowCount),
        status: 'error',
      };
    });
    let errorCount = 0;
    let lowCount = 0;
    array.forEach(v => {
      errorCount = errorCount + v.errorCount;
      lowCount = lowCount + v.lowCount;
    });

    const errorArray = array.slice();

    errorArray.push({
      status: 'yes',
      value: totalCount - errorCount,
      percentage: this.fetchPercentage(totalCount, totalCount - errorCount),
    });

    const lowArray = array.slice();

    lowArray.push({
      status: 'yes',
      value: lowTotalCount - lowCount,
      percentage: this.fetchPercentage(lowTotalCount, lowTotalCount - lowCount),
    });

    const lowResultList = lowArray.map(item => {
      if (item.status === 'error') {
        return {
          ...item,
          value: item.lowCount,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    this.errorPowerList = errorArray;
    this.seatPowerList = lowResultList;
  }
  fetchPercentage(total, count) {
    if (total && count) {
      return `${((count / total - 0) * 100).toFixed(0)}%`;
    } else {
      return 0;
    }
  }
  showSafeModal(index) {
    const { timeStamp, avgSafetyRateDTOS } = this.safeTyIndexList[index];
    const columns = [
      {
        title: '楼栋号',
        key: 'buildName',
        width: 300,
      },
      {
        title: '安全指数',
        key: 'avgSafetyRate',
        width: 60,
      },
    ];
    const modal = createModal(
      () => (
        <SafeModal
          columns={columns}
          data={avgSafetyRateDTOS
            .slice(0, 10)
            .sort((a, b) => a.avgSafetyRate - b.avgSafetyRate)}
          onRowClick={item => {
            this.$emit('buildIdJump', item.buildId);
            modal.handleClose();
          }}
          showArrow
        />
      ),
      {
        title: `${dayjs(timeStamp).format('YYYY-MM-DD')} 楼栋安全指数`,
        width: this.$size(508).toString(),
        showButton: false,
      },
    );
  }
}
</script>

<style lang="less" module>
.overview_wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  .projress {
    height: 11.58%;
  }
  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    &.visitor {
      padding-top: 0.2rem;
    }
  }
  .chart {
    height: 70%;
    padding: 0 0.2rem;
    display: flex;
    .col_wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-right: 0.2rem;
      overflow: hidden;
      &:last-child {
        margin-right: 0;
      }
    }
    .col_item {
      flex: 1;
      margin-bottom: 0.2rem;
    }
    .col_item_small {
      flex: 8;
      margin-bottom: 0.2rem;
    }
    .col_item_big {
      flex: 10;
      margin-bottom: 0.2rem;
    }
  }
  .safe_index {
    flex: 1;
    padding: 0 0.2rem;
    padding-bottom: 0.2rem;
  }
}
</style>
