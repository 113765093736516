<template>
  <div :class="$style.warningModal">
    <p>确定删除此张人工添加的图片？</p>
  </div>
</template>

<script>
import { Component, Vue, Emit } from 'vue-property-decorator';
@Component
export default class DeleteImage extends Vue {
  @Emit('success')
  @Emit('close')
  submit() {}

  @Emit('close')
  close() {}
}
</script>

<style lang="less" module>
@commonColor: rgba(13, 200, 254, 1);
.warningModal {
  padding: 0 0.2rem;
  color: #fff;
}
</style>
