<template>
  <div :class="$style.container">
    <no-data-chart v-if="isNoData" />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize';

const lineOptions = (data, nowSize) => {
  const seriesData = data.map(e => e.value);
  const min = Math.min(...seriesData);
  const dataZoom = [
    {
      type: 'inside',
      startValue: data.length - 6,
      endValue: data.length,
      zoomLock: true,
    },
  ];
  return {
    dataZoom,
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(3,29,63,0.6)', // 通过设置rgba调节背景颜色与透明度
      color: '#fff',
      borderWidth: '1',
      borderColor: '#0DC8FE',
      padding: nowSize(5),
      textStyle: {
        color: '#fff',
        fontSize: nowSize(14),
      },
      axisPointer: {
        type: 'line',
        z: 1,
        lineStyle: {
          color: '#fff',
          opacity: 0.2,
        },
      },
      formatter: function(params) {
        return `<span>${params[0].name}： ${params[0].value}%</span>`;
      },
      extraCssText: 'border-radius: 0; padding: 10px;',
    },
    grid: {
      x: 50,
      y: 30,
      x2: 20,
      y2: 40,
    },

    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: data.map(e => e.time),
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
      },
      triggerEvent: true,
    },
    yAxis: {
      min: min,
      max: 100, // 设置y轴坐标最大值
      interval: 20, // 间隔
      splitLine: {
        show: false,
      },
      type: 'value',
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
        formatter: '{a|{value}%}',
        width: 20,
        rich: {
          a: {
            align: 'left',
          },
        },
      },
    },
    series: [
      {
        name: 'test',
        type: 'line',
        stack: '总量',
        smooth: true,
        symbol: 'circle',
        symbolSize: 8,
        hoverAnimation: true,
        data: seriesData,
        itemStyle: {
          normal: {
            color: data.length > 1 ? 'rgba(0,0,0,0)' : '#05D3FF', // 背景渐变色
            lineStyle: {
              width: 3,
              type: 'solid',
              color: '#4fd6d2',
            },
          },
          emphasis: {
            color: '#05D3FF',
            borderWidth: 10,
            borderColor: 'rgba(5, 211, 255,0.5)',
            lineStyle: {
              width: 2,
              type: 'dotted',
              color: '#1dc2f1', // 折线的颜色
            },
          },
        }, // 线条样式
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(80,141,255,0.39)',
              },
              {
                offset: 0.34,
                color: 'rgba(56,155,255,0.25)',
              },
              {
                offset: 1,
                color: 'rgba(38,197,254,0.00)',
              },
            ]),
          },
        }, // 区域颜
      },
      {
        name: 'tes',
        type: 'bar',
        barWidth: 22,
        itemStyle: {
          color: 'transparent',
        },
        barGap: '-100%',
        barCategoryGap: '40%',
        data: data.map(() => 100),
        animation: false,
      },
    ],
  };
};
@Component({
  mixins: [resize],
})
export default class SafeBuild extends Vue {
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  @Prop({ type: String, default: 'safeIndex' }) id;
  mounted() {
    if (this.data && this.data.length) this.initChart();
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    if (!this.chart) {
      this.chart = echarts.init(document.getElementById(this.id));
      const options = lineOptions(this.data, this.$size);
      this.chart.setOption(options);
    }
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
}
</style>
