<template>
  <span
    :id="id"
    :style="styleObject"
    :class="$style.content"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
  >
    <span v-if="value !== 0">{{ value || nullValue }}</span>
    <span v-else>{{ value }}</span>
    <span v-if="showTooltip && style" :class="$style.tooltip" :style="style">
      {{ value }}
    </span>
  </span>
</template>

<script>
let id = 0;
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
@Component
export default class Ellipsis extends Vue {
  @Prop({ type: [String, Number], default: '' }) value;
  @Prop({ type: String, default: '无' }) nullValue;
  @Prop({ type: Object, default: () => {} }) styleObject;
  id = 'ellipsis' + id++;
  showTooltip = false;
  style = null;
  @Watch('value')
  valueChange() {
    this.$nextTick(this.init);
  }
  mounted() {
    this.init();
  }
  init() {
    const cellChild = document.getElementById(this.id).parentElement;
    const range = document.createRange();
    range.setStart(cellChild, 0);
    range.setEnd(cellChild, cellChild.childNodes.length);
    const rangeWidth = range.getBoundingClientRect().width;
    const padding =
      (parseInt(getComputedStyle(cellChild, 'paddingLeft'), 10) || 0) +
      (parseInt(getComputedStyle(cellChild, 'paddingRight'), 10) || 0);
    this.showTooltip = Boolean(rangeWidth + padding > cellChild.offsetWidth);
  }
  mouseleave() {
    this.style = null;
  }
  mouseenter(e) {
    if (!this.showTooltip) return;
    const { offsetX: top, offsetY: left } = e;
    this.style = { top, left };
  }
}
</script>

<style lang="less" module>
.content {
  .tooltip {
    position: fixed;
    z-index: 1000;
    display: none;
    white-space: normal;
    text-align: center;
    font-size: 0.14rem;
    padding: 0.15rem;
    color: #fff;
    background-color: #051231;
    border: 1px solid #06caf7;
  }
  &:hover .tooltip {
    display: block;
  }
}
</style>
