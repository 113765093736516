import request from '../request';

const serviceName = '/iot-electric-box/api/device-binds';

/**
 * @name 按楼栋id获取绑定安监终端
 */
export function getElectricByBuild(buildId) {
  return request(`${serviceName}/buildId/${buildId}`, {
    method: 'GET',
    auth: false,
  });
}
