<template>
  <no-data-chart v-if="isNoData" />
  <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize';
const lineOptions = (data, nowSize) => {
  const category = data.map(e => e.name);
  const series1 = {
    type: 'bar',
    stack: '总量',
    label: {
      show: true,
      position: 'inside',
      formatter: ({ data }) => {
        return data ? `${data}%` : '';
      },
      textStyle: {
        color: '#ffffff',
        fontSize: nowSize(12),
      },
    },
    barCategoryGap: '60%',
    itemStyle: {
      normal: {
        color: '#ec7e35',
      },
    },
    data: data.map(e => e.data[0]),
  };
  const series2 = {
    type: 'bar',
    stack: '总量',
    label: {
      show: true,
      position: 'inside',
      formatter: ({ data }) => {
        return data ? `${data}%` : '';
      },
      textStyle: {
        color: '#ffffff',
        fontSize: nowSize(12),
      },
    },
    // barWidth: 20,
    // barHeight: 20,
    barCategoryGap: '60%',
    itemStyle: {
      normal: {
        color: '#fa4229',
      },
    },
    data: data.map(e => e.data[1]),
  };
  return {
    grid: {
      x: 30,
      y: 30,
      x2: 20,
      y2: 30,
      containLabel: true,
      right: '3%',
    },
    xAxis: {
      min: 0,
      max: 100,
      splitLine: {
        show: false,
      },
      type: 'value',
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
      },
    },
    yAxis: {
      splitLine: {
        show: false,
      },
      type: 'category',
      // data: this.sevenSenseData.name,
      data: category,
      max: 3,
      nameGap: 12,
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#b8b8bb',
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
      },
    },
    series: [series1, series2],
  };
};
@Component({
  mixins: [resize],
})
export default class AcrossBar extends Vue {
  @Prop({ type: String, default: 'acrossBar' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  mounted() {
    if (this.data && this.data.length) this.initChart();
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    if (!this.chart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = lineOptions(this.data, this.$size);
    this.chart.setOption(options);
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
}
</style>
