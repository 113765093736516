<template>
  <div :class="$style.container" ref="container">
    <a-form-model
      :model="form"
      ref="form"
      hideRequiredMark
      :class="$style.form"
      validateOnRuleChange
      :rules="rules"
    >
      <a-form-model-item
        :labelCol="{ span: 5 }"
        labelAlign="left"
        :wrapperCol="{ span: 19 }"
        prop="password"
      >
        <template slot="label">{{
          $t('matterwebset.screenmanage.sharingway')
        }}</template>
        <a-radio-group v-model="setPassword">
          <a-radio :value="0">{{
            $t('matterwebset.screenmanage.nopassword')
          }}</a-radio>
          <a-radio :value="1">{{
            $t('matterwebset.screenmanage.setpassword')
          }}</a-radio>
        </a-radio-group>
        <a-input
          v-if="setPassword"
          @change="input"
          v-model="form.password"
          :placeholder="$t('iotScreenManage.pleaseSetFourPwd')"
          :class="$style.password"
        />
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 5 }"
        labelAlign="left"
        :wrapperCol="{ span: 19 }"
        prop="effectiveDate"
      >
        <template slot="label">{{
          $t('matterwebset.screenmanage.linkvalidity')
        }}</template>
        <a-radio-group v-model="form.effectiveDate">
          <a-radio value="FOREVER">{{
            $t('matterwebset.screenmanage.forever')
          }}</a-radio>
          <a-radio value="THIRTY">{{
            $t('matterwebset.screenmanage.thirty')
          }}</a-radio>
          <a-radio value="SEVEN">{{
            $t('matterwebset.screenmanage.seven')
          }}</a-radio>
          <a-radio value="ONE">{{
            $t('matterwebset.screenmanage.one')
          }}</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { generate } from '@/services/things/screen-manage';
const initForm = {
  password: '',
  effectiveDate: 'SEVEN',
};
@Component
export default class ShareForm extends Vue {
  @Prop({ type: Object, required: true }) data;
  form = {};
  setPassword = 0;
  get rules() {
    return {
      password: [
        {
          required: this.setPassword,
          message: this.$t('iotScreenManage.pleaseSetPwd'),
          trigger: 'blur',
        },
        {
          len: 4,
          message: this.$t('iotScreenManage.pleaseSetFourPwd'),
          trigger: 'change',
        },
      ],
    };
  }
  created() {
    this.form = JSON.parse(JSON.stringify(initForm));
  }
  async getValue() {
    await this.$refs.form.validate();
    const { projectId, projectName } = this.data;
    const shareInfo = await generate({
      ...this.form,
      password: this.setPassword ? this.form.password : '',
      type: 'PROJECT',
      projectId,
    });
    return {
      ...shareInfo,
      projectName,
      shareUrl: `${window.location.origin}/iot/generalScreen/projectProof/${shareInfo.id}`,
    };
  }
  /**
   * @name 密码锁定只能用字母+数字4位
   */
  input({ target: { value } }) {
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    value = value.substring(0, 4);
    this.form.password = value;
    this.$refs.form.validateField('password');
  }
}
</script>

<style lang="less" module>
@themeColor: #05d3ff;

.container {
  color: @themeColor;
  font-size: 14px;
  padding-bottom: 90px;
  :global {
    input[type='text'] {
      background-color: transparent;
      border-color: #5bd1fa50;
      font-size: 14px;
      color: @themeColor;
      margin-left: unset;
      width: 200px;
    }
    .ant-input:hover,
    .ant-input:focus {
      border-color: @themeColor;
    }
    input::placeholder {
      color: #047396;
    }
    .ant-form-item-label label {
      color: @themeColor;
    }
    .ant-radio-wrapper {
      color: @themeColor;
    }
    .ant-radio-inner,
    .ant-radio-checked .ant-radio-inner,
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner {
      border-color: @themeColor;
    }
    .ant-radio-inner::after {
      background-color: @themeColor;
    }
    // .ant-radio-wrapper:first-child {
    //   width: 120px;
    // }
    .ant-radio-wrapper {
      margin-right: 20px;
    }
  }

  .formItem {
    display: inline-block;
    width: 26.59%;

    :global(.ant-col.ant-form-item-control-wrapper) {
      float: right;
      width: 90%;
    }
    &:first-child {
      width: 40%;
      :global(.ant-col.ant-form-item-control-wrapper) {
        width: 60%;
      }
    }
  }
  .buttonList {
    text-align: right;
    margin-top: 130px;
  }
  .inSelect {
    width: 160px;
    // margin-right: 10px;
    // &:last-child {
    //   margin-right: 0;
    // }
  }
  .password {
    width: 174px;
    margin-left: 20px;
  }
}
</style>
